import { useState, useEffect } from "react";
import styled from "styled-components";
import Plot from "react-plotly.js";

import { MainLayout } from "common/layout";
import { TextBox } from "features/widgets/widgetlibrary/TextDisplay/TextDisplay";
import { useCustomerId } from "features/customers/hooks/customersHooks";
import { Skeleton } from "antd";

/** visual components */

const Wrapper = styled.div`
  height: 100%;
  background-color: #f0f0f0;
  overflow: auto;
`;

const UsageScreen = styled.div`
  padding: 20px;
`;

const dataColors = [
  "rgb(74, 168, 222)",
  "rgb(216, 105, 153)",
  "rgb(119,98,221)",
  "#cccccc",
];

/** Month component */

let totalData;
const MonthUsage = () => {
  // init
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const customerId = useCustomerId();

  // fetch data on render
  useEffect(() => {}, []);

  const calculateTotalFromData = (data) => {
    if (!data) return 0;
    return data.reduce((acc, el) => {
      return acc + el.bytes;
    }, 0);
  };

  totalData = (calculateTotalFromData(data) / (1024 * 1024.0)).toFixed(2);
  const numDevices = data ? data.length : 0;
  if (!data) return null;
  const topDevices = data.sort((a, b) => b.bytes - a.bytes).slice(0, 3);
  // for pie chart
  let config = { staticPlot: true };
  let layout = {
    height: 200,
    width: 200,
    plot_bgcolor: "black",
    paper_bgcolor: "transparent",
    margin: {
      b: 30,
      l: 30,
      r: 30,
      t: 10,
    },
    annotations: [
      {
        font: {
          size: 9,
        },
        showarrow: false,
        text: "TCUs by Data Usage",
        x: 0.17,
        y: 0.5,
      },
    ],
    showlegend: false,
  };
  const topDeviceData = topDevices.map((device) => {
    return (device.bytes / calculateTotalFromData(data)).toFixed(2);
  });
  const topDeviceTotalData = topDeviceData.reduce(
    (acc, el) => acc + parseFloat(el),
    0.0
  );
  const completeTopDeviceData = [...topDeviceData, 1 - topDeviceTotalData];
  const pieChartData = [
    {
      values: completeTopDeviceData.map((d) => d * 100.0),
      hole: 0.8,
      type: "pie",
      marker: {
        colors: dataColors,
      },
      textinfo: "none",
    },
  ];

  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const monthName = months[new Date().getMonth()];

  return (
    <Skeleton loading={isLoading} active style={{ padding: "2em 1em" }}>
      {/* <div style={{ padding: "0 1em" }}> */}
      <h2
        style={{
          textAlign: "center",
          fontFamily: "PilatWide",
          fontWeight: "100",
          padding: "25px 5px",
        }}
      >
        This Month ({monthName})
      </h2>

      <div style={{ display: "flex", padding: ".5em 0", alignItems: "center" }}>
        <div
          style={{
            flex: "1",
            minHeight: "150px",
            textAlign: "center",
            borderRight: "2px solid #ccc",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <TextBox
            value={
              <>
                {numDevices} <span className="signal-unit">x</span>
              </>
            }
            title={"Number of Devices"}
            size={{ height: 1, width: 1 }}
            isMapModalLayout={false}
          />
        </div>
        <div
          style={{
            flex: "1",
            minHeight: "150px",
            textAlign: "center",
            borderRight: "2px solid #ccc",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <TextBox
            value={
              <>
                {totalData} <span className="signal-unit">MB</span>
              </>
            }
            title={"Data Used"}
            size={{ height: 1, width: 1 }}
            isMapModalLayout={false}
          />
        </div>
        <div
          style={{
            flex: "1",
            minHeight: "150px",
            textAlign: "center",
            borderRight: "2px solid #ccc",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <TextBox
            value={
              <>
                {(totalData / numDevices).toFixed(2)}{" "}
                <span className="signal-unit">MB</span>
              </>
            }
            title={"Avg per Device"}
            size={{ height: 1, width: 1 }}
            isMapModalLayout={false}
          />
        </div>
        <div
          style={{
            flex: "1",
            minHeight: "150px",
            padding: "0 50px",
            textAlign: "center",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div style={{ width: "250px", margin: "0 20px" }}>
              <TextBox
                value={
                  <div style={{ fontSize: "13px" }}>
                    {topDevices.map((device, i) => {
                      const count = (device.bytes / (1024 * 1024.0)).toFixed(2);
                      return (
                        <p
                          key={device.product_sn_id}
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <span>{device.name}:</span>{" "}
                          <strong style={{ color: dataColors[i] }}>
                            {count} mb
                          </strong>
                        </p>
                      );
                    })}
                  </div>
                }
                title={"Top Users"}
                size={{ height: 1, width: 1 }}
                isMapModalLayout={false}
              />
            </div>
            <div>
              <Plot
                style={{ position: "relative" }}
                layout={layout}
                config={config}
                data={pieChartData}
              />
            </div>
          </div>
        </div>
      </div>
      {/* </div> */}
    </Skeleton>
  );
};

/** Main Component */

export const TCUUsagePage = () => {
  // perform render
  return (
    <MainLayout>
      <Wrapper className="usagepage-wrapper">
        <UsageScreen>
          <MonthUsage />
        </UsageScreen>
      </Wrapper>
    </MainLayout>
  );
};
