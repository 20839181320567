import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { BASE_API_URL } from "config";
import storage from "utils/storage";

const baseWidgetsQuery = fetchBaseQuery({
  baseUrl: `${BASE_API_URL}/api/widgets`,
  prepareHeaders: (headers) => {
    const accessToken = storage.getAccessToken();
    if (accessToken) headers.set("Authorization", `Bearer ${accessToken}`);
    return headers;
  },
});

export const widgetsApi = createApi({
  reducerPath: "widgetsApi",
  baseQuery: baseWidgetsQuery,
  tagTypes: ["widgets", "widget instances", "widget instance signals"],
  endpoints: (builder) => ({
    getWidgetsForCustomer: builder.query({
      query: (customerId) => `/instance?customer_id=${customerId}`,
      providesTags: ["widget instances"],
    }),
    addWidgetForCustomer: builder.mutation({
      query: ({ widget_instance, widget_instance_signals }) => ({
        url: "/instance",
        method: "POST",
        body: { widget_instance, widget_instance_signals },
      }),
      invalidatesTags: ["widget instances", "widget instance signals"],
    }),
    updateWidgetInstances: builder.mutation({
      query: ({ widget_instances, customerId }) => ({
        url: `/update_instances`,
        method: "POST",
        body: { widget_instances },
      }),
      async onQueryStarted(
        { widget_instances, customerId },
        { dispatch, queryFulfilled }
      ) {
        const patchResult = dispatch(
          widgetsApi.util.updateQueryData(
            "getWidgetsForCustomer",
            customerId,
            (draft_instances) => {
              if (!widget_instances.length) return draft_instances;
              const currentLayoutId = widget_instances[0].layout_id;
              const filtered_draft_instances = draft_instances.filter(
                (instance) => instance.layout_id !== currentLayoutId
              );
              const replacement_instances = [
                ...filtered_draft_instances,
                ...widget_instances,
              ];
              // immer doesn't allow for direct reassignment, so this splice directly modifies the entire array
              draft_instances.splice(
                0,
                draft_instances.length,
                ...replacement_instances
              );
            }
          )
        );
        try {
          await queryFulfilled;
        } catch (error) {
          console.error("error:", error);
          patchResult.undo();
          // if the above undo behaves unexpectedly, we can also try:
          // dispatch(apiSlice.util.invalidateTags(["WidgetInstances", "WidgetInstanceSignals"]))
        }
      },
    }),
    updateSingleWidgetInstance: builder.mutation({
      query: ({ widget_instance, widget_instance_signals }) => ({
        url: "/edit",
        method: "POST",
        body: { widget_instance, widget_instance_signals },
      }),
      invalidatesTags: ["widget instances", "widget instance signals"],
    }),
    updateWidgetInstanceSettings: builder.mutation({
      query: (data) => ({
        url: `/instance/settings/edit/${data.id}`,
        method: "POST",
        body: data.settings,
      }),
      invalidatesTags: ["widget instances"],
    }),
    duplicateWidgetInstance: builder.mutation({
      query: (widget_instance_id) => ({
        url: `/instance/${widget_instance_id}/duplicate`,
        method: "POST",
      }),
      invalidatesTags: ["widget instances", "widget instance signals"],
    }),
    deleteWidgetForCustomer: builder.mutation({
      query: (widget_instance_id) => ({
        url: `/instance/${widget_instance_id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["widget instances", "widget instance signals"],
    }),
    getWidgetGroups: builder.query({
      query: () => "/groups",
    }),
    getWidgetTypesForCustomer: builder.query({
      query: (customer_id) => `/types?customer_id=${customer_id}`,
    }),
    getWidgetInstanceSignalsForCustomer: builder.query({
      query: (customerId) => `/instance_signals?customer_id=${customerId}`,
      providesTags: ["widget instance signals"],
    }),
  }),
});

export const {
  useGetWidgetGroupsQuery,
  useAddWidgetForCustomerMutation,
  useUpdateSingleWidgetInstanceMutation,
  useUpdateWidgetInstanceSettingsMutation,
  useDuplicateWidgetInstanceMutation,
  useGetWidgetsForCustomerQuery,
  useUpdateWidgetInstancesMutation,
  useDeleteWidgetForCustomerMutation,
  useGetWidgetTypesForCustomerQuery,
  useGetWidgetInstanceSignalsForCustomerQuery,
} = widgetsApi;
