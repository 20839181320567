import styled from "styled-components";
import moment from "moment";
import { useCustomerId } from "features/customers/hooks/customersHooks";
import { useLiveMQTTData, useSetDataHistory } from "features/units/hooks";
import { S3_LOADING_SPINNER } from "utils/defines";
import { useGetSubscriptionForCustomerQuery } from "features/customers/redux";
import { useGetLatestSNDataForCustomerQuery } from "features/units/redux";
import { useGetProductSettingsForCustomerQuery } from "features/applications/redux";
import { formatForRedux } from "features/units/utils";
import {
  useGetWidgetsForCustomerQuery,
  useGetWidgetInstanceSignalsForCustomerQuery,
} from "features/widgets/redux";
import { useEffect } from "react";

type Props = {
  children: React.ReactNode;
};

const LoadingSpinnerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  padding: 20px;
`;

const LoadingSpinnerInner = styled.div`
  width: 100%;
  max-width: 550px;
`;

export const DataManager = ({ children }: Props) => {
  const customerId = useCustomerId() || null;
  const { isLoading: widgetInstancesIsLoading } =
    useGetWidgetsForCustomerQuery(customerId);
  const { isLoading: widgetInstanceSignalsIsLoading } =
    useGetWidgetInstanceSignalsForCustomerQuery(customerId);
  const { isLoading: productSettingsIsLoading } =
    useGetProductSettingsForCustomerQuery(customerId);
  const { isLoading: customerSubscriptionIsLoading } =
    useGetSubscriptionForCustomerQuery(customerId);
  const { data: latestSNData } = useGetLatestSNDataForCustomerQuery(customerId);

  useLiveMQTTData();
  const setDataHistory = useSetDataHistory();

  const dataIsLoading = [
    widgetInstancesIsLoading,
    widgetInstanceSignalsIsLoading,
    productSettingsIsLoading,
    customerSubscriptionIsLoading,
  ].some(Boolean);

  useEffect(() => {
    // preload SN telemetry data
    if (latestSNData && latestSNData.length && !dataIsLoading) {
      const remappedSNData = latestSNData.map((dataPoint: any) => ({
        ...dataPoint,
        timestamp_recorded: moment.utc(dataPoint.timestamp_recorded).valueOf(),
      }));

      const formattedNewestData = formatForRedux(remappedSNData);
      setDataHistory(formattedNewestData);
    }
  }, [dataIsLoading, latestSNData]);

  if (dataIsLoading)
    return (
      <LoadingSpinnerWrapper>
        <LoadingSpinnerInner>
          <img src={S3_LOADING_SPINNER} alt="Loading" />
        </LoadingSpinnerInner>
      </LoadingSpinnerWrapper>
    );

  return <>{children}</>;
};

export default DataManager;
