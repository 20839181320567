export const widgetTypesList = {
  all: "All",
  fleetwide: "Fleetwide",
  product: "Product",
};

export const widgetsList = {
  textDisplay: {
    widget_type: widgetTypesList.product,
    min_signals: 1,
    max_signals: 1,
    displayName: "Text Display",
    componentName: "TextDisplay",
    key: "textDisplay",
  },
  table: {
    widget_type: widgetTypesList.product,
    min_signals: 1,
    max_signals: 12,
    displayName: "Table",
    componentName: "Table",
    key: "table",
  },
  minMaxDisplay: {
    widget_type: widgetTypesList.product,
    min_signals: 2,
    max_signals: 2,
    displayName: "Min Max Display",
    componentName: "MinMaxDisplay",
    key: "minMaxDisplay",
  },
  image: {
    widget_type: widgetTypesList.all,
    displayName: "Image",
    componentName: "Image",
    key: "image",
  },
  anyText: {
    widget_type: widgetTypesList.all,
    displayName: "Any Text",
    componentName: "AnyText",
    key: "anyText",
  },
  map: {
    widget_type: widgetTypesList.product,
    displayName: "Map",
    componentName: "Map",
    key: "map",
  },
  lineRedux: {
    widget_type: widgetTypesList.all,
    min_signals: 1,
    max_signals: 8,
    displayName: "Line Redux",
    componentName: "LineRedux",
    key: "lineRedux",
  },
  signalAggregator: {
    widget_type: widgetTypesList.fleetwide,
    min_signals: 1,
    max_signals: 1,
    displayName: "Signal Aggregator",
    componentName: "SignalAggregator",
    key: "signalAggregator",
  },
  demoSampleContent: {
    widget_type: widgetTypesList.all,
    displayName: "[Demo]SampleContent",
    componentName: "DemoSampleContent",
    key: "demoSampleContent",
  },
  simpleChart: {
    widget_type: widgetTypesList.fleetwide,
    min_signals: 1,
    max_signals: 1,
    displayName: "Simple Chart",
    componentName: "SimpleChart",
    key: "simpleChart",
  },
  dashboardMap: {
    widget_type: widgetTypesList.fleetwide,
    displayName: "Dashboard Map",
    componentName: "DashboardMap",
    key: "dashboardMap",
  },
  dashboardDataUsage: {
    widget_type: widgetTypesList.fleetwide,
    displayName: "Dashboard Data Usage",
    componentName: "DashboardDataUsage",
    key: "dashboardDataUsage",
  },
  dashboardProductPresentation: {
    widget_type: widgetTypesList.fleetwide,
    displayName: "Dashboard Product Presentation",
    componentName: "DashboardProductPresentation",
    key: "dashboardProductPresentation",
  },
  dashboardStatusPieChart: {
    widget_type: widgetTypesList.fleetwide,
    displayName: "Dashboard Status Pie Chart",
    componentName: "DashboardStatusPieChart",
    key: "dashboardStatusPieChart",
  },
  dashboardDataUsageBarChart: {
    widget_type: widgetTypesList.fleetwide,
    displayName: "Dashboard Data Usage Bar Chart",
    componentName: "DashboardDataUsageBarChart",
    key: "dashboardDataUsageBarChart",
  },
  timelineGraph: {
    widget_type: widgetTypesList.fleetwide,
    displayName: "Timeline Graph",
    componentName: "TimelineGraph",
    key: "timelineGraph",
  },
  distributionBarGraph: {
    widget_type: widgetTypesList.fleetwide,
    min_signals: 1,
    max_signals: 1,
    displayName: "Distribution Bar Graph",
    componentName: "DistributionBarGraph",
    key: "distributionBarGraph",
  },
  energyAggregation: {
    widget_type: widgetTypesList.fleetwide,
    min_signals: 2,
    max_signals: 2,
    displayName: "Energy Aggregation",
    componentName: "DashboardEnergyAggregation",
    key: "dashboardEnergyAggregation",
  },
};
