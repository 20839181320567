import SettingsField, {
  ExtendedSettingsField,
} from "features/widgets/widgetlibrary/SettingsField";
import { RadioButton } from "common/elements";
import { ColorPicker } from "antd";

export const DashboardStatusPieChartSettings = ({
  changeSettings,
  getField,
}) => {
  return (
    <div>
      <ExtendedSettingsField label="Background">
        <RadioButton
          name="hide_background"
          id="visible"
          value={0}
          checked={!getField("background_hide", 0)}
          onChange={(e) =>
            changeSettings("background_hide", e.target.checked ? 0 : 1)
          }
          text="Visible"
        />
        <RadioButton
          name="hide_background"
          id="hidden"
          value={1}
          checked={getField("background_hide", 0)}
          onChange={(e) =>
            changeSettings("background_hide", e.target.checked ? 1 : 0)
          }
          text="Hidden"
        />
      </ExtendedSettingsField>
      <ExtendedSettingsField label="Live Color">
        <ColorPicker
          format="hex"
          defaultValue={getField("live_color")}
          onChange={(e) => {
            const { metaColor } = e;
            const rgb = {
              r: (metaColor?.r).toFixed(0),
              g: (metaColor?.g).toFixed(0),
              b: (metaColor?.b).toFixed(0),
            };
            changeSettings("live_color", rgb);
          }}
          getPopupContainer={(triggerNode) => {
            return triggerNode.parentNode;
          }}
        />
      </ExtendedSettingsField>
      <ExtendedSettingsField label="Last Day Color">
        <ColorPicker
          format="hex"
          defaultValue={getField("last_day_color")}
          onChange={(e) => {
            const { metaColor } = e;
            const rgb = {
              r: (metaColor?.r).toFixed(0),
              g: (metaColor?.g).toFixed(0),
              b: (metaColor?.b).toFixed(0),
            };
            changeSettings("last_day_color", rgb);
          }}
          getPopupContainer={(triggerNode) => {
            return triggerNode.parentNode;
          }}
        />
      </ExtendedSettingsField>
      <ExtendedSettingsField label="Last Week Color">
        <ColorPicker
          format="hex"
          defaultValue={getField("last_week_color")}
          onChange={(e) => {
            const { metaColor } = e;
            const rgb = {
              r: (metaColor?.r).toFixed(0),
              g: (metaColor?.g).toFixed(0),
              b: (metaColor?.b).toFixed(0),
            };
            changeSettings("last_week_color", rgb);
          }}
          getPopupContainer={(triggerNode) => {
            return triggerNode.parentNode;
          }}
        />
      </ExtendedSettingsField>
      <ExtendedSettingsField label="Last Month Color">
        <ColorPicker
          format="hex"
          defaultValue={getField("last_month_color")}
          onChange={(e) => {
            const { metaColor } = e;
            const rgb = {
              r: (metaColor?.r).toFixed(0),
              g: (metaColor?.g).toFixed(0),
              b: (metaColor?.b).toFixed(0),
            };
            changeSettings("last_month_color", rgb);
          }}
          getPopupContainer={(triggerNode) => {
            return triggerNode.parentNode;
          }}
        />
      </ExtendedSettingsField>
      <ExtendedSettingsField label="Over A Month Color">
        <ColorPicker
          format="hex"
          defaultValue={getField("over_a_month_color")}
          onChange={(e) => {
            const { metaColor } = e;
            const rgb = {
              r: (metaColor?.r).toFixed(0),
              g: (metaColor?.g).toFixed(0),
              b: (metaColor?.b).toFixed(0),
            };
            changeSettings("over_a_month_color", rgb);
          }}
          getPopupContainer={(triggerNode) => {
            return triggerNode.parentNode;
          }}
        />
      </ExtendedSettingsField>
    </div>
  );
};
