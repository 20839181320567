import SettingsField from "features/widgets/widgetlibrary/SettingsField";

export const DemoSampleContentSettings = ({ changeSettings, getField }) => {
  let selectedContent = getField("selectedDemoContent", "0");
  return (
    <div>
      <SettingsField label="Mode">
        <select
          value={selectedContent}
          onChange={(e) =>
            changeSettings("selectedDemoContent", e.target.value)
          }
        >
          <option value="0">Fleet Parameters</option>
        </select>
      </SettingsField>
    </div>
  );
};
