import styled from "styled-components";
import { useSelector } from "react-redux";
import { CirclePicker as Picker } from "react-color";

const Wrapper = styled.div`
  width: 100%;
  display: flex;
`;

const ColorPicker = ({ color, onChange, colorType = "rgb" }) => {
  const customer = useSelector((state) => state.user.customer);
  const { color_primary, color_secondary } = customer;
  const colors = [
    color_primary,
    color_secondary,
    "#9c27b0",
    "#3f51b5",
    "#03a9f4",
    "#009688",
    "#ffeb3b",
    "#505050",
  ].filter((c) => c); // filter out null colors (haven't been set)

  const handleChange = (color) => {
    onChange(color[colorType]);
  };
  return (
    <Wrapper className="color-picker-wrapper">
      <Picker
        className="modal-field-element"
        color={color}
        colors={colors}
        onChangeComplete={handleChange}
      />
    </Wrapper>
  );
};
export default ColorPicker;
