import { useState, useRef, useEffect, useCallback, memo } from "react";
import _ from "lodash";
import styled from "styled-components";
import EventCard from "./EventCard/EventCard";
import { Map } from "features/widgets/widgetlibrary/Map/Map";
import {
  TCU_EVENTS,
  LINECHART_SIDEBAR_WIDTH,
  EVENT_CARD_HEIGHT,
  EVENT_VIRTUALIZATION_BUFFER,
  SIDEBAR_EVENTS,
  SIDEBAR_MAP,
  SIDEBAR_FILTER,
  SIDEBAR_STACKED,
  getInfoForEvent,
} from "utils/defines";
import { useCurrentSNId } from "features/units/hooks";
import { Icon } from "common";

const EventsBodyContainer = styled.div`
  height: ${({ height }) => height}px;
`;

const EventsContainer = styled.div`
  border-radius: 6px;
  display: ${({ sidebarMode }) => sidebarMode === SIDEBAR_MAP && "none"};
  flex: 1;
  height: ${({ containerHeight }) => containerHeight}px;
  margin-bottom: 15px;
  overflow-y: scroll;
  padding: 10px;
  position: relative;
  scrollbar-color: #f5f5f5;

  &::-webkit-scrollbar {
    width: 20px;
  }

  &::-webkit-scrollbar-thumb {
    background-clip: content-box;
    border-radius: 10px;
    border: 7px solid transparent;
    height: 50px;
  }

  &:hover::-webkit-scrollbar-thumb {
    background-color: #696969;
  }
`;

const EventsFiltersContainer = styled.div`
  background-color: #f5f5f5;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow-y: scroll;
  padding: 10px;
`;

const EventsFilterListButton = styled.button`
  border-radius: 5px;
  border: 2px solid black;
  margin: 0 2px 6px 2px;
  width: 110px;
`;

const EventsFilterListButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

const EventsFilterContainerTitle = styled.h4`
  width: 100%;
  padding: 3px 0px;
  text-align: center;
`;

const EventNamesList = styled.div`
  display: flex;
  flex-direction: column;
`;

const EventsSidebarContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  height: ${({ height }) => height}px;
  opacity: ${({ active }) => (active ? 1 : 0)};
  overflow: hidden;
  padding: 10px;
  position: absolute;
  right: 0;
  transform: ${({ active }) =>
    active ? "translateX(0)" : "translateX(250px)"};
  transition: transform 0.3s ease;
  width: ${({ width }) => width}px;
`;

const FilterEventIcon = styled.div`
  display: flex;
  margin-right: 10px;
  width: 20px;
`;
const FilterListInput = styled.input`
  display: flex;
  flex-direction: row;
  margin-left: 5px;
  margin-right: 12px;
`;

const FilterEventText = styled.p`
  margin-bottom: auto;
`;
const FilterListLabel = styled.label`
  display: flex;
  padding: 6px 0;
`;

const MapContainer = styled.div`
  flex: ${({ visible }) => (visible ? 1 : 0)};
  padding: ${({ visible }) => (visible ? "10px" : 0)};
  position: relative;
  width: 100%;
  height: ${({ visible }) => (visible ? "auto" : 0)};
  overflow: ${({ visible }) => (visible ? "visible" : "hidden")};
`;

const NoEventsToDisplayText = styled.div`
  display: flex;
  justify-content: center;
  padding-left: 15px;
`;

const areEqual = (prevProps, nextProps) => {
  if (
    prevProps.active !== nextProps.active ||
    prevProps.sidebarMode !== nextProps.sidebarMode ||
    prevProps.showEvents !== nextProps.showEvents ||
    prevProps.height !== nextProps.height ||
    prevProps.measureCursorDate.valueOf() !==
      nextProps.measureCursorDate.valueOf()
  ) {
    return false;
  }

  if (
    prevProps.activeEvent?.id !== nextProps.activeEvent?.id ||
    prevProps.oldestEvent?.id !== nextProps.oldestEvent?.id ||
    prevProps.lineChartDateRange?.toString() !==
      nextProps.lineChartDateRange?.toString()
  ) {
    return false;
  }

  if (
    !_.isEqual(prevProps.events, nextProps.events) || // Deep compare arrays
    !_.isEqual(prevProps.filteredEventIds, nextProps.filteredEventIds) ||
    !_.isEqual(prevProps.eventStateValues, nextProps.eventStateValues)
  ) {
    return false;
  }

  if (
    prevProps.clearFilters?.toString() !== nextProps.clearFilters?.toString() ||
    prevProps.filterEvents?.toString() !== nextProps.filterEvents?.toString() ||
    prevProps.getEventStateValueForCustomEvent?.toString() !==
      nextProps.getEventStateValueForCustomEvent?.toString() ||
    prevProps.goToEventDate?.toString() !==
      nextProps.goToEventDate?.toString() ||
    prevProps.handleFetchEvents?.toString() !==
      nextProps.handleFetchEvents?.toString() ||
    prevProps.selectAllFilters?.toString() !==
      nextProps.selectAllFilters?.toString() ||
    prevProps.setFilteredEvents?.toString() !==
      nextProps.setFilteredEvents?.toString() ||
    prevProps.setShowEvents?.toString() !== nextProps.setShowEvents?.toString()
  ) {
    return false;
  }

  // If all checks pass, no re-render needed
  return true;
};

const EventsSidebar = memo(
  ({
    active,
    activeEvent,
    clearFilters,
    events,
    eventStateValues,
    filteredEventIds,
    filterEvents,
    getEventStateValueForCustomEvent,
    goToEventDate,
    handleFetchEvents,
    height,
    layoutId,
    lineChartDateRange,
    measureCursorDate,
    oldestEvent,
    selectAllFilters,
    setShowEvents,
    sidebarMode,
  }) => {
    const [startIndex, setStartIndex] = useState(0);
    const [endIndex, setEndIndex] = useState(40);
    const currentSNId = useCurrentSNId();
    const eventsContainerRef = useRef(null);

    const eventsMode = sidebarMode === SIDEBAR_EVENTS;
    const mapMode = sidebarMode === SIDEBAR_MAP;
    const filterMode = sidebarMode === SIDEBAR_FILTER;
    const stackedMode = sidebarMode === SIDEBAR_STACKED;

    const eventsTabIsActive = !mapMode && !filterMode;
    const mapTabIsActive = !eventsMode && !filterMode;
    const filterTabIsActive = sidebarMode === SIDEBAR_FILTER;

    const handleScroll = useCallback(() => {
      if (mapMode || filterMode) return;
      const scrollTop = eventsContainerRef.current.scrollTop;
      const scrollHeight = eventsContainerRef.current.scrollHeight;
      const clientHeight = eventsContainerRef.current.clientHeight;
      const scrollPercentage =
        (scrollTop / (scrollHeight - clientHeight)) * 100;
      const thresholdPercentage = 80;

      if (scrollPercentage > thresholdPercentage) {
        handleFetchEvents(oldestEvent.timestamp_recorded);
      }
      const newStartIndex = Math.max(
        0,
        Math.floor(scrollTop / EVENT_CARD_HEIGHT) - EVENT_VIRTUALIZATION_BUFFER
      );

      const newEndIndex = Math.min(
        Math.ceil((scrollTop + height) / EVENT_CARD_HEIGHT) +
          EVENT_VIRTUALIZATION_BUFFER,
        events.length
      );

      if (newStartIndex !== startIndex || newEndIndex !== endIndex) {
        setStartIndex(newStartIndex);
        setEndIndex(newEndIndex);
      }
    }, [
      endIndex,
      events?.length,
      filterMode,
      handleFetchEvents,
      height,
      mapMode,
      oldestEvent?.timestamp_recorded,
      startIndex,
    ]);

    useEffect(() => {
      if (mapMode || filterMode) return;
      eventsContainerRef?.current?.addEventListener("scroll", handleScroll);
      return () => {
        eventsContainerRef?.current?.removeEventListener(
          "scroll",
          handleScroll
        );
      };
    }, [events, filterMode, handleScroll, mapMode, sidebarMode]);

    return (
      <EventsSidebarContainer
        active={active}
        height={height}
        width={LINECHART_SIDEBAR_WIDTH}
      >
        {eventsTabIsActive && (
          <EventsContainer ref={eventsContainerRef} sidebarMode={sidebarMode}>
            <EventsBodyContainer
              height={(events.length - 1) * EVENT_CARD_HEIGHT}
            >
              {!events.length ? (
                <NoEventsToDisplayText>
                  No events to display
                </NoEventsToDisplayText>
              ) : (
                <>
                  {active &&
                    events
                      .slice(startIndex, endIndex)
                      .map((event, index) => (
                        <EventCard
                          activeEvent={activeEvent}
                          event={event}
                          eventStateValues={eventStateValues}
                          getEventStateValueForCustomEvent={
                            getEventStateValueForCustomEvent
                          }
                          goToEventDate={goToEventDate}
                          key={(index + startIndex) * EVENT_CARD_HEIGHT}
                          top={(index + startIndex) * EVENT_CARD_HEIGHT}
                          setShowEvents={setShowEvents}
                        />
                      ))}
                </>
              )}
            </EventsBodyContainer>
          </EventsContainer>
        )}
        <MapContainer
          sidebarMode={sidebarMode}
          visible={mapTabIsActive || stackedMode}
        >
          {active && (
            <Map
              isSidebarMap={true}
              lineChartStartDate={lineChartDateRange[0]}
              lineChartEndDate={lineChartDateRange[1]}
              currentProduct={currentSNId || ""}
              measureCursorDate={measureCursorDate}
              layoutId={layoutId}
            />
          )}
        </MapContainer>
        {filterTabIsActive && (
          <EventsFiltersContainer>
            <EventsFilterContainerTitle>
              Filter Events
            </EventsFilterContainerTitle>
            <EventsFilterListButtonContainer>
              <EventsFilterListButton onClick={selectAllFilters}>
                Select All
              </EventsFilterListButton>
              <EventsFilterListButton onClick={clearFilters}>
                Select None
              </EventsFilterListButton>
            </EventsFilterListButtonContainer>
            <EventNamesList>
              {TCU_EVENTS.map((tcu_event) => {
                const event_id = tcu_event.getIdx();
                const { title, icon } = getInfoForEvent(event_id);
                return (
                  <FilterListLabel key={event_id}>
                    <FilterListInput
                      checked={filteredEventIds.includes(event_id)}
                      onChange={() => {}}
                      onClick={() => filterEvents(event_id)}
                      type="checkbox"
                    />
                    <FilterEventIcon>
                      <Icon hostedImage={icon} />
                    </FilterEventIcon>
                    <FilterEventText>{title}</FilterEventText>
                  </FilterListLabel>
                );
              })}
            </EventNamesList>
          </EventsFiltersContainer>
        )}
      </EventsSidebarContainer>
    );
  },
  areEqual
);

export default EventsSidebar;
