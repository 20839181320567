import styled from "styled-components";

const Wrapper = styled.div``;

export const SettingsField = ({ label, children }) => {
  return (
    <Wrapper className="modal-field-wrapper color-picker-wrapper">
      <label className="modal-field-label">{label}</label>
      <div className="modal-field-element">{children}</div>
    </Wrapper>
  );
};

export const ExtendedSettingsField = ({ label, children }) => {
  return (
    <Wrapper className="modal-field-wrapper color-picker-wrapper">
      <label className="modal-field-label-extended">{label}</label>
      <div className="modal-field-element">{children}</div>
    </Wrapper>
  );
};

export default SettingsField;
