import styled from "styled-components";
import { useSelector } from "react-redux";
import { formatNumber } from "utils";
import {
  Wrapper,
  WidgetTable,
  Row,
  RowCell,
  SignalValue,
} from "../TileComponents/Table";

const TableTitle = styled.h4``;
const TableBody = styled.div``;

const htmlForSignalValue = (val, symbol) => (
  <span>
    <b>{val}</b>
    <span style={{ marginLeft: "4px" }}>{symbol}</span>
  </span>
);

export const Table = ({
  currentProduct: currentProductSN,
  instance_signals,
  _newestDataArray,
  getSettingsField,
}) => {
  const dataHistory = useSelector((state) => state.productSNData.dataHistory);
  const dataHistoryForSN = dataHistory ? dataHistory[currentProductSN] : [];

  // set or hide title
  let settingsTitle = getSettingsField("title", null);
  let tableTitle = settingsTitle ? (
    <TableTitle>{settingsTitle}</TableTitle>
  ) : null;

  // how to organize signal data
  const signalSort = (a, b) => a.position - b.position;
  // const dataFilter = (instanceSig) => (d) =>
  //   d.signal_id === instanceSig.signal_id;

  // turning signals into rows
  const mapSignalsToRows = (signalInstance, i) => {
    // alternating row colors
    let rowStyle = i % 2 === 0 ? { backgroundColor: "rgba(1,1,1,.05)" } : {};

    // get IS and data
    const { IS, signal: signalObj } = signalInstance;
    if (!dataHistoryForSN || !signalObj)
      return (
        <Row key={i} style={rowStyle}>
          <RowCell>Loading...</RowCell>
          <SignalValue>&nbsp;</SignalValue>
        </Row>
      );
    const dataHistoryForSNForSignal = dataHistoryForSN[signalObj.id]
      ? dataHistoryForSN[signalObj.id]
      : null;
    // the latest dataPoint for this signal
    // const signalDatum = dataHistoryForSN.find(dataFilter(signalInstance));
    const signalDatum = dataHistoryForSNForSignal
      ? dataHistoryForSNForSignal[dataHistoryForSNForSignal.length - 1]
      : null;
    // apparently some signalInstances don't have the signal object attached to them
    // and this function fails if we can't query for precision
    // todo: standardize signalInstance creation
    let signalPrecision = signalObj ? signalObj.precision : 0;

    // how to display data depending on type
    const formatSignal = (signalDatum) => {
      const { data_value } = signalDatum;
      return formatNumber(data_value, signalPrecision); //  + ' | ' + signal_id.toString()
    };

    // key/val
    let signalKey = () => IS.getSignalName();
    let signalSymbol = IS.getUnitSymbol();
    let signalValue = signalDatum ? formatSignal(signalDatum) : "";

    // render value or "blank"
    const renderSignalValue = () =>
      signalValue ? htmlForSignalValue(signalValue, signalSymbol) : "- - - -";

    // render the full row
    return (
      <Row key={i} style={rowStyle}>
        <RowCell>{signalKey()}</RowCell>
        <SignalValue>{renderSignalValue()}</SignalValue>
      </Row>
    );
  };

  // actually perform the signal->row conversion
  const signalRows = () =>
    instance_signals.sort(signalSort).map(mapSignalsToRows);
  const TableHeadRowStyle = {
    borderBottom: "1px solid grey",
    fontSize: "20px",
  };

  return (
    <Wrapper>
      {tableTitle}
      <WidgetTable>
        <Row style={TableHeadRowStyle}>
          <RowCell>KEY</RowCell>
          <SignalValue>VALUE</SignalValue>
        </Row>
        <TableBody>{signalRows()}</TableBody>
      </WidgetTable>
    </Wrapper>
  );
};
