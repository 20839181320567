import SettingsField from "features/widgets/widgetlibrary/SettingsField";
export const MapSettings = ({ changeSettings, getField }) => {
  return (
    <div>
      <SettingsField label="Refresh Rate">
        <select
          type="checkbox"
          value={getField("refresh_rate", "")}
          onChange={(e) => changeSettings("refresh_rate", e.target.value)}
        >
          <option value="" disabled>
            Select a rate...
          </option>
          <option value="1000">1 second</option>
          <option value="5000">5 seconds</option>
          <option value="15000">15 seconds</option>
          <option value="30000">30 seconds</option>
          <option value="60000">1 minute</option>
        </select>
      </SettingsField>
      <SettingsField label="Show Last Location Only">
        <input
          type="checkbox"
          checked={getField("last_location_only", false)}
          onChange={(e) => {
            changeSettings("last_location_only", e.target.checked);
          }}
        />
      </SettingsField>
    </div>
  );
};
