import SettingsField from "features/widgets/widgetlibrary/SettingsField";

export const MinMaxDisplaySettings = ({ changeSettings, getField }) => {
  return (
    <div>
      <SettingsField label="Title">
        <input
          value={getField("widgetTitle", "")}
          onChange={(e) => changeSettings("widgetTitle", e.target.value)}
        />
      </SettingsField>
    </div>
  );
};
