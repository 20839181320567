import SettingsField from "../SettingsField";

export const LineReduxSettings = ({ changeSettings, getField }) => {
  return (
    <div>
      <SettingsField label="Basic Mode?">
        <input
          type="checkbox"
          checked={getField("basic", "") ? true : false}
          onChange={(e) => changeSettings("basic", e.target.checked ? 1 : 0)}
        />
      </SettingsField>
      {getField("basic", "") && (
        <>
          <SettingsField label="Relative Interval">
            <select
              value={getField("relativeInterval", "")}
              onChange={(e) =>
                changeSettings("relativeInterval", e.target.value)
              }
            >
              <option value=".5">Last 30 seconds</option>
              <option value="1">Last 1 minutes</option>
              <option value="5">Last 5 minutes</option>
              <option value="15">Last 15 minutes</option>
              <option value="30">Last 30 minutes</option>
              <option value="60">Last 60 minutes</option>
              <option value="1440">Last day</option>
            </select>
          </SettingsField>
          <SettingsField label="Auto Refresh">
            <input
              type="checkbox"
              checked={getField("autoRefresh", "") ? true : false}
              onChange={(e) =>
                changeSettings("autoRefresh", e.target.checked ? 1 : 0)
              }
            />
          </SettingsField>
        </>
      )}
      <SettingsField label="Graph Title">
        <input
          value={getField("graphTitle", "")}
          onChange={(e) => changeSettings("graphTitle", e.target.value)}
        />
      </SettingsField>
    </div>
  );
};
