import SettingsField, {
  ExtendedSettingsField,
} from "features/widgets/widgetlibrary/SettingsField";
import { ColorPicker } from "antd";

export const DashboardEnergyAggregationSettings = ({
  changeSettings,
  getField,
}) => {
  return (
    <div>
      <ExtendedSettingsField label="Title">
        <input
          value={getField("widgetTitle", "")}
          onChange={(e) => changeSettings("widgetTitle", e.target.value)}
        />
      </ExtendedSettingsField>
      <ExtendedSettingsField label="Font-Family">
        <select
          value={getField("widgetFontFamily", "1")}
          onChange={(e) => changeSettings("widgetFontFamily", e.target.value)}
        >
          <option value="1">Default</option>
          <option value="2">Rubik</option>
          <option value="3">PilatWide</option>
        </select>
      </ExtendedSettingsField>
      <ExtendedSettingsField label="Graph Type">
        <select
          value={getField("widgetGraphType", "1")}
          onChange={(e) => changeSettings("widgetGraphType", e.target.value)}
        >
          <option value="1">Total Energy Usage Per Day</option>
          <option value="2">Total Charge Usage Per Day</option>
          <option value="3">Total Discharge Usage Per Day</option>
        </select>
      </ExtendedSettingsField>
      <ExtendedSettingsField label="Number of Days">
        <input
          type={"number"}
          min="2"
          max="30"
          value={getField("numDays", "")}
          onChange={(e) => changeSettings("numDays", e.target.value)}
          placeholder={"Between 2 - 30 days"}
        />
      </ExtendedSettingsField>
      <ExtendedSettingsField label="Graph Color">
        <ColorPicker
          format="hex"
          defaultValue={getField("widgetGraphColor")}
          onChange={(e) => {
            const { metaColor } = e;
            const rgb = {
              r: (metaColor?.r).toFixed(0),
              g: (metaColor?.g).toFixed(0),
              b: (metaColor?.b).toFixed(0),
            };
            changeSettings("widgetGraphColor", rgb);
          }}
          getPopupContainer={(triggerNode) => {
            return triggerNode.parentNode;
          }}
        />
      </ExtendedSettingsField>
    </div>
  );
};
