import moment from "moment";
import * as Defines from "./defines";
export * from "./Color";
export * from "./useInterval";
export * from "./deepDiffMapper";

export const formatNumber = (num, precision = 0) => {
  if (isNaN(num)) {
    return num;
  }
  if (precision == null) {
    precision = 0;
  }
  return parseFloat(num).toFixed(precision);
};

export const convertMillisecondsEstimated = (ms) => {
  const milliseconds = Math.abs(ms);
  const seconds = Math.floor(milliseconds / 1000);
  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);
  const days = Math.floor(hours / 24);
  const months = Math.floor(days / 30);
  const years = Math.floor(days / 365);

  if (years > 0)
    return `${years * Math.sign(ms)} year${years !== 1 ? "s" : ""}`;
  if (months > 0)
    return `${months * Math.sign(ms)} month${months !== 1 ? "s" : ""}`;
  if (days > 0) return `${days * Math.sign(ms)} day${days !== 1 ? "s" : ""}`;
  if (hours > 0)
    return `${hours * Math.sign(ms)} hour${hours !== 1 ? "s" : ""}`;
  if (minutes > 0)
    return `${minutes * Math.sign(ms)} minute${minutes !== 1 ? "s" : ""}`;
  if (seconds > 0)
    return `${seconds * Math.sign(ms)} second${seconds !== 1 ? "s" : ""}`;
  return `${ms * Math.sign(ms)} millisecond${milliseconds !== 1 ? "s" : ""}`;
};

export const convertMilliseconds = (ms) => {
  let milliseconds = Math.abs(Math.floor(ms));
  let seconds = Math.abs(Math.floor(ms / 1000));
  let minutes = Math.abs(Math.floor(seconds / 60));
  let hours = Math.abs(Math.floor(minutes / 60));
  let days = Math.abs(Math.floor(hours / 24));
  let months = Math.abs(Math.floor(days / 30));
  let years = Math.abs(Math.floor(days / 365));

  milliseconds %= 1000;
  seconds %= 60;
  minutes %= 60;
  hours %= 24;
  days %= 30;
  months %= 12;
  return `
    ${Math.sign(ms) < 0 ? "- " : ""}
    ${years ? `${years}y ` : ""}
    ${months ? `${months}mo ` : ""}
    ${days ? `${days}d ` : ""}
    ${hours ? `${hours}h ` : ""}
    ${minutes ? `${minutes}m ` : ""}
    ${seconds ? `${seconds}s ` : ""}
    ${milliseconds ? `${milliseconds}ms ` : ""}
  `;
};

export const getTimeSince = (timestamp) => {
  // the date coming from the db is recorded as utc, so ensure it here

  if (moment.utc(timestamp).isValid()) {
    const refMoment = new moment.utc(timestamp);
    const rightNow = new moment.utc();

    // if it's in the future, diff present moment instead
    if (refMoment.isAfter(rightNow)) return rightNow.fromNow();

    // return the diff
    return refMoment.fromNow();
  } else {
    return "Unknown";
  }
};

export const getIsUnitActive = (
  timestamp,
  secondsPassedToBeConsideredInactive = 30
) => {
  const differenceInSeconds = moment.utc().diff(timestamp, "seconds");
  return (
    differenceInSeconds <= secondsPassedToBeConsideredInactive &&
    timestamp !== -Infinity
  );
};

// matches moment.js fromNow() but makes it shorter
export const getTimeSinceTruncated = (timestamp) => {
  if (!timestamp) return "n/a";

  let milliseconds = new moment().diff(new moment(timestamp)),
    seconds = Math.floor(milliseconds / 1000),
    minutes = Math.floor(milliseconds / 1000 / 60),
    hours = Math.floor(milliseconds / (1000 * 60) / 60),
    days = Math.floor(milliseconds / (1000 * 60 * 60) / 24);

  if (!milliseconds) return "n/a";

  if (seconds < 44) return "<1m";

  if (seconds < 89) return "~1m";

  if (minutes > 1 && minutes <= 44) return `${minutes}m`;

  if (minutes > 44 && minutes <= 89) return `~1h`;

  if (minutes > 89 && hours <= 21) return `${hours}h`;

  if (hours > 21 && hours <= 35) return `~1d`;

  if (hours > 35 && days <= 25) return `${days}d`;

  if (days > 25) return `>25d`;

  return "n/a";
};

export const getSignalStrengthIcon = (signalCalculation) => {
  if (!signalCalculation || signalCalculation === 0)
    return Defines.S3_SIGNAL_0_NONE;
  if (signalCalculation > 80) return Defines.S3_SIGNAL_5_GREAT;
  if (signalCalculation > 60) return Defines.S3_SIGNAL_4_GOOD;
  if (signalCalculation > 40) return Defines.S3_SIGNAL_3_AVERAGE;
  if (signalCalculation > 20) return Defines.S3_SIGNAL_2_POOR;
  return Defines.S3_SIGNAL_1_VERY_POOR;
};

// Convert Bytes to KB
export const getBytesAsKB = (data) => {
  return data / Defines.BYTES_AS_KB;
};
// Convert Bytes to MB
export const getBytesAsMB = (data) => {
  return getBytesAsKB(data) / Defines.BYTES_AS_KB;
};
// Convert Bytes to GB
export const getBytesAsGB = (data) => {
  return getBytesAsMB(data) / Defines.BYTES_AS_KB;
};
/**
 * Get Date as String formatted to YYYY-MM-DD
 * @param {*} date: JS Date Object
 * @returns
 */
export const getDateFormattedString = (date) => {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  return `${year}-${month}-${day}`;
};
/**
 * Get Past Dates by providing Number of Days and optionally, a start date
 * @param {*} numOfDays
 * @param {*} startDate
 * @returns
 */
export const getPastDatesByNumberOfDays = (numOfDays, startDate = "") => {
  let pastDays = [];
  const startingDate =
    startDate === "" ? new Date() : new Date(`${startDate}T00:00:00`);
  for (let i = numOfDays; i > 0; i--) {
    let tmpDate = new Date();
    tmpDate.setDate(startingDate.getDate() - i);
    pastDays.push(getDateFormattedString(tmpDate));
  }
  return pastDays;
};
