import SettingsField from "features/widgets/widgetlibrary/SettingsField";
import { ColorPicker } from "antd";

export const DistributionBarGraphSettings = ({ changeSettings, getField }) => {
  return (
    <div>
      <SettingsField label="Title">
        <input
          value={getField("wTitle", "")}
          onChange={(e) => changeSettings("wTitle", e.target.value)}
        />
      </SettingsField>
      <SettingsField label="Font-Family">
        <select
          value={getField("wFontFamily", "1")}
          onChange={(e) => changeSettings("wFontFamily", e.target.value)}
        >
          <option value="1">Default</option>
          <option value="2">Rubik</option>
          <option value="3">PilatWide</option>
        </select>
      </SettingsField>
      <SettingsField label="X-Title">
        <input
          value={getField("wXTitle", "")}
          onChange={(e) => changeSettings("wXTitle", e.target.value)}
        />
      </SettingsField>
      <SettingsField label="Y-Title">
        <input
          value={getField("wYTitle", "")}
          onChange={(e) => changeSettings("wYTitle", e.target.value)}
        />
      </SettingsField>
      <SettingsField label="Orientation">
        <select
          value={getField("wOrientation", "v")}
          onChange={(e) => changeSettings("wOrientation", e.target.value)}
        >
          <option value="v">Vertical</option>
          <option value="h">Horizontal</option>
        </select>
      </SettingsField>
      <SettingsField label="Number of Bins">
        <select
          value={getField("wNumOfBins", "1")}
          onChange={(e) => changeSettings("wNumOfBins", e.target.value)}
        >
          <option value="1">1</option>
          <option value="2">2</option>
          <option value="3">3</option>
          <option value="4">4</option>
          <option value="5">5</option>
        </select>
      </SettingsField>
      <SettingsField label="Bin Min. Value">
        <input
          type="number"
          value={getField("wBinMinValue", "")}
          onChange={(e) => changeSettings("wBinMinValue", e.target.value)}
        />
      </SettingsField>
      <SettingsField label="Bin Max. Value">
        <input
          type="number"
          value={getField("wBinMaxValue", "")}
          onChange={(e) => changeSettings("wBinMaxValue", e.target.value)}
        />
      </SettingsField>
      <SettingsField label="Marker Color">
        <ColorPicker
          format="hex"
          defaultValue={getField("wMarkerColor")}
          onChange={(e) => {
            const { metaColor } = e;
            const rgb = {
              r: (metaColor?.r).toFixed(0),
              g: (metaColor?.g).toFixed(0),
              b: (metaColor?.b).toFixed(0),
            };
            changeSettings("wMarkerColor", rgb);
          }}
          getPopupContainer={(triggerNode) => {
            return triggerNode.parentNode;
          }}
        />
      </SettingsField>
    </div>
  );
};
