import { Spinner } from "common/Spinner";
import { useGetCustomerApplicationPairingHistoryQuery } from "features/applications/redux";
import { useCustomerId } from "features/customers/hooks";
import { useEffect, useState } from "react";
import Plot from "react-plotly.js";
import { withSize } from "react-sizeme";
import styled, { useTheme } from "styled-components";
const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  container-type: inline-size;
`;
const months = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];
/**
 * Get Last 12 Months by set current month
 * @param {*} currentMonth: 0-11
 * @returns
 */
const getLastNMonths = (currentMonth, numberofMonths) => {
  let lastMonths = [];
  for (let i = 0; i < numberofMonths; i++) {
    let monthIndex = (currentMonth - i + 12) % 12;
    lastMonths.push(months[monthIndex]);
  }
  return lastMonths.reverse();
};

/**
 * Get Number of Months by Relative Range selected
 * @param {*} timeRange : From Settings Options
 */
const getMonthsByRelativeRange = (timeRange) => {
  switch (timeRange) {
    case "last_week": {
      return 0.25;
    }
    case "last_month": {
      return 1;
    }
    case "last_three_months": {
      return 3;
    }
    case "last_six_months": {
      return 6;
    }
    case "last_year":
    default: {
      return 12;
    }
  }
};

const TotalPairedUnitsPerMonthGraph = ({
  qCustomerApplicationPairingHistory,
  isWidgetLoaded,
  title = "",
  fontFamily = "",
  xTitle = "",
  yTitle = "",
  timeRange,
  size,
  theme,
}) => {
  const [plotData, setPlotData] = useState([]);
  const [plotFontFamily, setPlotFontFamily] = useState("");
  const [plotXRange, setPlotXRange] = useState([]);
  const [plotYRange, setPlotYRange] = useState([]);

  useEffect(() => {
    if (!isWidgetLoaded) return;

    const numberOfPoints = getMonthsByRelativeRange(timeRange);
    const currentMonth = new Date().getMonth();
    const last12Months = getLastNMonths(currentMonth, 12);
    const lastNMonths = getLastNMonths(currentMonth, numberOfPoints);

    // fetched per month totals
    const perMonthTotals = qCustomerApplicationPairingHistory?.per;

    // initialize and set date-range for longest (last 12 months)
    let monthsData = {};
    for (let i = 0; i < 12; i++) {
      monthsData[last12Months[i]] = { month: 0, total: 0 };
    }
    // set monthly totals
    let total = 0;
    Object.keys(perMonthTotals)
      .sort((a, b) => a - b)
      .reverse()
      .forEach((item) => {
        const monthTotal = perMonthTotals[item];
        if (item < 13) {
          const monthsAgoByName = months[(currentMonth - item + 12) % 12];
          monthsData[monthsAgoByName].month = monthTotal;
        } else {
          total += monthTotal;
        }
      });

    // update running totals per month
    Object.keys(monthsData).forEach((item) => {
      total += monthsData[item].month;
      monthsData[item].total = total;
    });

    let lastNMonthsTotal = [];
    let lastNMonthsPerMonth = [];
    lastNMonths.forEach((item) => {
      const monthData = monthsData[item];
      lastNMonthsPerMonth.push(monthData.month);
      lastNMonthsTotal.push(monthData.total);
    });

    // update font family
    if (fontFamily === "2") {
      setPlotFontFamily("Rubik");
    } else if (fontFamily === "3") {
      setPlotFontFamily("PilatWide");
    }

    setPlotData([
      {
        x: lastNMonths,
        y: lastNMonthsPerMonth,
        mode: "lines+markers",
        type: "scatter",
        name: "Added",
        line: {
          color: theme.themePrimary,
          width: 3,
        },
      },
      {
        x: lastNMonths,
        y: lastNMonthsTotal,
        mode: "lines+markers",
        type: "scatter",
        name: "Total",
        line: {
          color: theme.themeSecondary,
          width: 3,
        },
      },
    ]);

    setPlotXRange([last12Months[0], last12Months[11]]);
    setPlotYRange([-1, total + 1]);
  }, [
    isWidgetLoaded,
    fontFamily,
    plotFontFamily,
    theme,
    qCustomerApplicationPairingHistory,
    timeRange,
  ]);

  return (
    <>
      <Plot
        style={{
          position: "relative",
          display: "inline-block",
          width: "100%",
          height: "100%",
        }}
        data={plotData}
        layout={{
          title: `${title}`,
          font: {
            family: `${plotFontFamily}`,
          },
          xaxis: {
            title: `${xTitle}`,
            range: plotXRange,
          },
          yaxis: {
            title: `${yTitle}`,
            range: plotYRange,
          },
          width: size.width,
          height: size.height,
          dragmode: false,
          responsive: true,
        }}
        config={{
          doubleClick: false,
        }}
      />
    </>
  );
};
const TimelineGraphWidget = ({ getSettingsField, size }) => {
  const customerID = useCustomerId();
  const theme = useTheme();
  const [isWidgetLoaded, setIsWidgetLoaded] = useState(false);

  // widget settings
  const widgetGraphType = getSettingsField("widgetGraphType");
  const widgetRelativeTimeRange = getSettingsField("widgetRelativeTimeRange");
  const widgetTitle = getSettingsField("widgetTitle", "");
  const widgetFontFamily = getSettingsField("widgetFontFamily");
  const widgetXTitle = getSettingsField("widgetXTitle", "");
  const widgetYTitle = getSettingsField("widgetYTitle", "");

  // load data
  const {
    data: qCustomerApplicationPairingHistory,
    isLoading: isQCustomerApplicationPairingHistoryLoading,
  } = useGetCustomerApplicationPairingHistoryQuery(
    { customer_id: customerID },
    { skip: customerID == null }
  );
  // update if widget loading state
  useEffect(() => {
    setIsWidgetLoaded(!isQCustomerApplicationPairingHistoryLoading);
  }, [isQCustomerApplicationPairingHistoryLoading]);

  // show spinner if widget not loaded
  if (!isWidgetLoaded) return <Spinner />;

  const renderTimelineGraphByType = () => {
    switch (widgetGraphType) {
      case "total_paired_units_per_month":
        return (
          <TotalPairedUnitsPerMonthGraph
            qCustomerApplicationPairingHistory={
              qCustomerApplicationPairingHistory
            }
            isWidgetLoaded={isWidgetLoaded}
            timeRange={widgetRelativeTimeRange}
            title={widgetTitle}
            fontFamily={widgetFontFamily}
            xTitle={widgetXTitle}
            yTitle={widgetYTitle}
            size={size}
            theme={theme}
          />
        );

      case "overall_total_paired_units_per_month":
        return <>Coming Soon...</>;

      default:
        return <>Coming Soon...</>;
    }
  };

  return (
    <>
      <Wrapper className="timeline-graph-wrapper">
        {renderTimelineGraphByType()}
      </Wrapper>
    </>
  );
};

export const TimelineGraph = withSize({ monitorHeight: true })(
  TimelineGraphWidget
);
