import { useState, useContext, useEffect } from "react";
import { throttle } from "lodash";
import { AuthExtendSessionModal } from "features/auth/components/AuthExtendSessionModal";
import { AuthContext } from "features/auth/contexts";
import { connect } from "react-redux";
import jwt_decode from "jwt-decode";
import storage from "utils/storage";
import { useInterval } from "utils";
import { Spinner } from "common/Spinner";
import styled from "styled-components";

type Props = {
  children: React.ReactNode;
  user: any;
};

const SignOutWrapper = styled.div`
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  left: 50%;
  padding: 40px;
  position: fixed;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 99999;
`;

const SessionManagerComponent = ({ children, user }: Props) => {
  const authContext = useContext(AuthContext);
  const [timeRemaining, setTimeRemaining] = useState("");
  const [isCloseDisabled, setIsCloseDisabled] = useState(false);
  const [isContinueDisabled, setIsContinueDisabled] = useState(false);
  const [extendSessionModal, setExtendSessionModal] = useState(false);
  const [lastActiveTime, setLastActiveTime] = useState(Date.now());
  const accessToken: any = storage.getAccessToken();
  const accessAuthInfo: any = jwt_decode(accessToken);
  const [accessExpDiff, setAccessExpDiff] = useState(
    accessAuthInfo?.exp - Date.now() / 1000
  ); // in seconds
  const [loggingOut, setLoggingOut] = useState(false);
  const [timeoutId, setTimeoutId] = useState<number | undefined>(undefined);

  // Auth Checking
  const CHECK_AUTH_EXPIRE_INTERVAL = 1000; // Time for periodic interval timer (in Milliseconds)
  const CHECK_AUTH_SESSION_MODAL_TOGGLE_LIMIT = 120; // Auth Session Timer remaining before toggle Extension Modal (in Seconds)
  const INACTIVITY_CHECK_INTERVAL = 1000 * 60; // Rate at which we ping user for activity (1 minute)
  const INACTIVITY_THRESHOLD = 1000 * 60 * 15; // Length of inactivity to consider a user as inactive (15 minutes)

  // cleanup functions
  useEffect(() => {
    return () => {
      setLoggingOut(false);
    };
  }, []);

  useEffect(() => {
    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [timeoutId]);

  // attach eventListeners to window to check if user is active
  useEffect(() => {
    const handleThrottledEvent = throttle(() => {
      // < 15 minutes (in seconds)
      if (accessExpDiff < 60 * 15) setLastActiveTime(Date.now());
    }, INACTIVITY_CHECK_INTERVAL);

    window.addEventListener("click", handleThrottledEvent);
    window.addEventListener("keydown", handleThrottledEvent);

    return () => {
      window.removeEventListener("click", handleThrottledEvent);
      window.removeEventListener("keydown", handleThrottledEvent);
    };
  }, [accessExpDiff]);

  const isUserActive = Date.now() - lastActiveTime < INACTIVITY_THRESHOLD;
  const extendSessionWithRefreshToken = async () => {
    const refreshToken: any = storage.getRefreshToken();
    const { idToken, accessToken, expiresIn } = await refreshAccess(
      user.auth_user_id,
      refreshToken
    );
    await storeSession({ accessToken, idToken, refreshToken, expiresIn });
  };

  useInterval(async () => {
    setAccessExpDiff(accessAuthInfo?.exp - Date.now() / 1000);
    if (user) {
      // minutes + seconds remaining
      const accessExpDiffMinute = Math.floor(accessExpDiff / 60).toFixed(0);
      const accessExpDiffSeconds = (
        Math.floor(accessExpDiff % 60) === 60
          ? 59
          : Math.floor(accessExpDiff % 60)
      )
        .toFixed(0)
        .padStart(2, "0"); // pad single digit (seconds) to format as 01..09

      // update time remaining display
      setTimeRemaining(`${accessExpDiffMinute}:${accessExpDiffSeconds}s`);
      if (accessExpDiff < 0 && !loggingOut) {
        setLoggingOut(true);
        const id = setTimeout(async () => {
          await authContext?.signOut();
          setLoggingOut(false);
        }, 2500);
        setTimeoutId(id as unknown as number);
      } else if (
        accessExpDiff < CHECK_AUTH_SESSION_MODAL_TOGGLE_LIMIT &&
        !isUserActive
      ) {
        // auto logout timer has reached first limit, toggle extension modal
        setExtendSessionModal(true);
      } else if (
        accessExpDiff < CHECK_AUTH_SESSION_MODAL_TOGGLE_LIMIT &&
        isUserActive &&
        !extendSessionModal
      ) {
        extendSessionWithRefreshToken();
      }
    }
  }, CHECK_AUTH_EXPIRE_INTERVAL);

  if (!authContext) return <>{children}</>;

  const { refreshAccess, storeSession } = authContext;

  // on Modal Continue
  const onContinueClick = async () => {
    // disable buttons
    setIsCloseDisabled(true);
    setIsContinueDisabled(true);

    // using refresh token, extend acccess token
    const refreshToken: any = storage.getRefreshToken();
    const { idToken, accessToken, expiresIn } = await refreshAccess(
      user.auth_user_id,
      refreshToken
    );
    await storeSession({ accessToken, idToken, refreshToken, expiresIn });

    setIsCloseDisabled(false);
    setIsContinueDisabled(false);
    setExtendSessionModal(false);
  };

  return (
    <>
      {!loggingOut && (
        <AuthExtendSessionModal
          timeRemaining={timeRemaining}
          isShow={extendSessionModal}
          hide={() => null}
          isCloseDisabled={isCloseDisabled}
          isContinueDisabled={isContinueDisabled}
          onContinueClick={onContinueClick}
        />
      )}
      {!extendSessionModal && !loggingOut && children}
      {loggingOut && authContext && (
        <SignOutWrapper className="logout-notification">
          <p style={{ fontSize: "20px" }}>Session expired. Logging out...</p>
          <Spinner />
        </SignOutWrapper>
      )}
    </>
  );
};

const mapStateToProps = (state: Props) => ({
  user: state.user.auth_user,
});

export const SessionManager = connect(
  mapStateToProps,
  null
)(SessionManagerComponent);
