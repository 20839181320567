// Layout Variables
export const MAP_MODAL = "map-modal";
export const PRODUCT_DASHBOARD = "product/dashboard";

// Line Chart Settings
export const ACTIVE_CURSOR = "activeCursor";
export const ACTIVE_EVENT = "activeEvent";
export const ARE_PLOT_POINTS_VISIBLE = "arePlotPointsVisible";
export const DIFF_DATE = "diffDate";
export const DURATION_DROPDOWN_TEXT = "durationDropdownText";
export const FILTERED_EVENT_IDS = "filteredEventIds";
export const FILTERED_EVENTS = "filteredEvents";
export const IS_DIFF_CURSOR_ENABLED = "isDiffCursorEnabled";
export const IS_TCU_UPLOAD_BUTTON_DISABLED = "isTCUButtonDisabled";
export const IS_TIME_SELECTED_VISIBLE = "isTimeSelectVisible";
export const IS_TOOLTIP_ACTIVE = "isToolTipActive";
export const LINE_CHART_DATE_RANGE = "lineChartDateRange";
export const LINE_CHART_IS_LIVE = "lineChartIsLive";
export const LINE_CHART_MAP_TYPE = "lineChartMapType";
export const LINE_CHART_SIDEBAR_ACTIVE = "lineChartSidebarActive";
export const MEASURE_DATE = "measureDate";
export const PLAY_MODE = "playMode";
export const REAL_TIME_TEXT_DISPLAY = "realTimeTextDisplay";
export const ZOOM_SCROLL_STATE = "zoomScrollState";
export const IS_LINE_CHART_FIRST_LOAD = "isLineChartFirstLoad";
export const SCROLL_ZOOM_STATE = "scrollZoomState";
export const SELECTED_CHART = "selectedChart";
export const SHOW_EVENTS = "showEvents";
export const SIDEBAR_MODE = "sidebarMode";
export const STACK_MODE = "stackMode";
export const TCU_UPLOAD_BUTTON_HOVERED = "tcuUploadButtonHovered";
export const VIEWABLE_DURATION = "viewableDuration";
export const VIEWABLE_UNIT = "viewableUnit";
export const Y_ZOOM_STATES = "yZoomStates";
export const ZOOM_LEVELS = "zoomLevels";

// Line Redux Zoom Limits (in milliseconds)
export const LINE_REDUX_ZOOM_IN_LIMIT_DURATION = 7.5 * 1000;
export const LINE_REDUX_ZOOM_OUT_LIMIT_DURATION = 1000 * 60 * 60 * 24 * 365;

// Application States
export const ACTIVE = "active";
export const RETIRED = "retired";

// Events Sidebar
export const EVENT_CARD_HEIGHT = 60;
export const EVENT_VIRTUALIZATION_BUFFER = 40; // Number of events to show before startIndex and after endIndex in EventsSidebar.js
export const SIDEBAR_EVENTS = "Events";
export const SIDEBAR_MAP = "Map";
export const SIDEBAR_STACKED = "Stacked";
export const SIDEBAR_FILTER = "Filter";

// Navigation
export const DEFAULT_LOGGED_IN_REDIRECT_URL = "/dashboard";
export const DEFAULT_LOGGED_OUT_REDIRECT_URL = "/";

// Live Data History
export const BUFFER_EMPTY_INTERVAL = 500;
export const KEEP_ALIVE_INTERVAL = 60000;

// Colors
export const GENERIC_CHECKBOX_BG_COLOR = "#444444";
export const COLOR_SUCCESS_GREEN = "#5b995f";
export const COLOR_ERROR_RED = "#ba3f43";
export const COLOR_CONNECTED_BLUE = "#284fc3";
export const UNDERLAY_BG_COLOR = "rgba(50, 50, 50, 0.7)";

// Features
export const ENABLE_ADD_PRODUCT_SN_FUNCTIONS = false;
export const ENABLE_DELETE_PRODUCT_SN_FUNCTIONS = false;
export const ENABLE_ADD_TCU_FUNCTIONS = false;
export const ENABLE_DELETE_TCU_FUNCTIONS = false;
export const ENABLE_PRODUCTOVERLAY_MODE_SWITCHER = false;
export const ENABLE_TILE_PREVIEW_MODAL = false;
export const ENABLE_RAW_PARAMETERSMODAL = false;
export const ENABLE_INCOMPLETE_TCUPAGE_FEATURES = false;

// Header
export const DEBUG_DISABLE_CUSTOMER_SWITCH = true;
export const CUSTOMERS_FOR_DEMO = [
  "Stafl Systems",
  "Stafl Fleet",
  "Genovation",
  "Arcimoto",
  "Advanced Logistics",
  "Peninsula Transit",
];

// S3 Icons
export const ICON_SIZE_XS = 16;
export const ICON_SIZE_SM = 20;
export const ICON_SIZE_MD = 24;
export const ICON_SIZE_LG = 28;
export const ICON_SIZE_XL = 32;

const S3_ICONS_BASE_URL =
  "https://fleet-assets.s3.us-west-1.amazonaws.com/design/ICONSET3/StaflSystemsFleet_ICONSET3_";
export const S3_ACCORDION_EXPAND = `${S3_ICONS_BASE_URL}AccordionExpand.svg`;
export const S3_ARROW_DOWN = `${S3_ICONS_BASE_URL}ArrowDown.svg`;
export const S3_ARROWS_EXPAND = `${S3_ICONS_BASE_URL}ArrowsExpand.svg`;
export const S3_ARROWS_ROTATE_ANIMATED = `${S3_ICONS_BASE_URL}ArrowsRotate-ANIM.svg`;
export const S3_ARROWS_ROTATE_ANIMATED_3 = `${S3_ICONS_BASE_URL}ArrowsRotate-ANIM3.svg`;
export const S3_ARROWS_ROTATE = `${S3_ICONS_BASE_URL}ArrowsRotate.svg`;
export const S3_ARROW_UP = `${S3_ICONS_BASE_URL}ArrowUp.svg`;
export const S3_BOOKMARK = `${S3_ICONS_BASE_URL}Bookmark.svg`;
export const S3_BROADCAST_ANIMATION = `${S3_ICONS_BASE_URL}Broadcast-ANIM.svg`;
export const S3_BROADCAST = `${S3_ICONS_BASE_URL}Broadcast.svg`;
export const S3_CARET_DOWN = `${S3_ICONS_BASE_URL}CaretDown.svg`;
export const S3_CARET_DOWN_ALT = `${S3_ICONS_BASE_URL}CaretDownAlt.svg`;
export const S3_CARET_LEFT = `${S3_ICONS_BASE_URL}CaretLeft.svg`;
export const S3_CARET_RIGHT = `${S3_ICONS_BASE_URL}CaretRight.svg`;
export const S3_CARET_UP = `${S3_ICONS_BASE_URL}CaretUp.svg`;
export const S3_CARET_UP_ALT = `${S3_ICONS_BASE_URL}CaretUpAlt.svg`;
export const S3_CHECKMARK = `${S3_ICONS_BASE_URL}Checkmark.svg`;
export const S3_CIRCLE_ELLIPSIS = `${S3_ICONS_BASE_URL}CircleEllipsis.svg`;
export const S3_CLOSE_X = `${S3_ICONS_BASE_URL}CloseX.svg`;
export const S3_COPY = `${S3_ICONS_BASE_URL}Copy.svg`;
export const S3_CURSOR_DIFFERENCE = `${S3_ICONS_BASE_URL}CursorDifference.svg`;
export const S3_CURSOR_MEASURE = `${S3_ICONS_BASE_URL}CursorMeasure.svg`;
export const S3_EDIT = `${S3_ICONS_BASE_URL}Edit.svg`;
export const S3_EVENT_CUSTOM = `${S3_ICONS_BASE_URL}EventCustom.svg`;
export const S3_EVENT_FLEET_CONNECT = `${S3_ICONS_BASE_URL}EventFleetConnect.svg`;
export const S3_EVENT_FLEET_DISCONNECT = `${S3_ICONS_BASE_URL}EventFleetDisconnect.svg`;
export const S3_EVENT_LAST_GASP = `${S3_ICONS_BASE_URL}EventLastGasp.svg`;
export const S3_EVENT_MULTIPLE_INDICATOR_NEWEST = `${S3_ICONS_BASE_URL}MultipleIndicatorNewest.svg`;
export const S3_EVENT_MULTIPLE_INDICATOR_OLDEST = `${S3_ICONS_BASE_URL}MultipleIndicatorOldest.svg`;
export const S3_EVENT_OTA_CANCEL = `${S3_ICONS_BASE_URL}EventOTACancel.svg`;
export const S3_EVENT_OTA_CHECK = `${S3_ICONS_BASE_URL}EventOTACheck.svg`;
export const S3_EVENT_OTA_DOWNLOADED = `${S3_ICONS_BASE_URL}EventOTADownloaded.svg`;
export const S3_EVENT_OTA_ERROR = `${S3_ICONS_BASE_URL}EventOTAError.svg`;
export const S3_EVENT_OTA_FINISH = `${S3_ICONS_BASE_URL}EventOTAFinish.svg`;
export const S3_EVENT_OTA_INSTALL = `${S3_ICONS_BASE_URL}EventOTAInstall.svg`;
export const S3_EVENT_OTA_START = `${S3_ICONS_BASE_URL}EventOTAStart.svg`;
export const S3_EVENT_POWER_ON = `${S3_ICONS_BASE_URL}EventPowerOn.svg`;
export const S3_EVENTS = `${S3_ICONS_BASE_URL}Events.svg`;
export const S3_EVENT_UNKNOWN = `${S3_ICONS_BASE_URL}EventUnknown.svg`;
export const S3_EVENT_UTC_RESET = `${S3_ICONS_BASE_URL}EventUTCReset.svg`;
export const S3_EVENT_UTC_SET = `${S3_ICONS_BASE_URL}EventUTCSet.svg`;
export const S3_EYE_OPEN = `${S3_ICONS_BASE_URL}EyeOpen.svg`;
export const S3_EYE_SLEEP = `${S3_ICONS_BASE_URL}EyeSleep.svg`;
export const S3_EYE_WAKE = `${S3_ICONS_BASE_URL}EyeWake.svg`;
export const S3_FILTER = `${S3_ICONS_BASE_URL}Filter.svg`;
export const S3_GLOBE = `${S3_ICONS_BASE_URL}Globe.svg`;
export const S3_GRAPH_BAR = `${S3_ICONS_BASE_URL}GraphBar.svg`;
export const S3_HIGH_RES_REQUEST_ANIM = `${S3_ICONS_BASE_URL}HiResRequest-ANIM.svg`;
export const S3_HIGH_RES_REQUEST_ANIM_2 = `${S3_ICONS_BASE_URL}HiResRequest-ANIM2.svg`;
export const S3_HIGH_RES_REQUEST = `${S3_ICONS_BASE_URL}HiResRequest.svg`;
export const S3_HIGH_RES_REQUEST_CANCEL = `${S3_ICONS_BASE_URL}HiResRequestCancel.svg`;
export const S3_HIGH_RES_REQUEST_P1 = `${S3_ICONS_BASE_URL}HiResRequestP1.svg`;
export const S3_HIGH_RES_REQUEST_P2 = `${S3_ICONS_BASE_URL}HiResRequestP2.svg`;
export const S3_HIGH_RES_REQUEST_P3 = `${S3_ICONS_BASE_URL}HiResRequestP3.svg`;
export const S3_HIGH_RES_REQUEST_P4 = `${S3_ICONS_BASE_URL}HiResRequestP4.svg`;
export const S3_HOME_SIGNAL = `${S3_ICONS_BASE_URL}HomeSignal.svg`;
export const S3_LINK_EXTERNAL = `${S3_ICONS_BASE_URL}LinkExternal.svg`;
export const S3_LIST_TOGGLE = `${S3_ICONS_BASE_URL}ListToggle.svg`;
export const S3_LIST_TOGGLE_ALT_1 = `${S3_ICONS_BASE_URL}ListToggleAlt1.svg`;
export const S3_LIST_LOCATION_OFF = `${S3_ICONS_BASE_URL}LocationOFF.svg`;
export const S3_LIST_LOCATION_ON = `${S3_ICONS_BASE_URL}LocationON.svg`;
export const S3_LOCK_CLOSED = `${S3_ICONS_BASE_URL}LockClosed.svg`;
export const S3_LOCK_OPEN = `${S3_ICONS_BASE_URL}LockOpen.svg`;
export const S3_LOG_OUT = `${S3_ICONS_BASE_URL}LogOut.svg`;
export const S3_MAP_PIN_UNIT_A = `${S3_ICONS_BASE_URL}MapPinUnitA.svg`;
export const S3_MAP_PIN_UNIT_A_FOCUS = `${S3_ICONS_BASE_URL}MapPinUnitAFocus.svg`;
export const S3_MAP_PIN_UNIT_B = `${S3_ICONS_BASE_URL}MapPinUnitB.svg`;
export const S3_MAP_PIN_UNIT_B_FOCUS = `${S3_ICONS_BASE_URL}MapPinUnitBFocus.svg`;
export const S3_MAP_PIN_UNIT_C = `${S3_ICONS_BASE_URL}MapPinUnitC.svg`;
export const S3_MAP_PIN_UNIT_C_FOCUS = `${S3_ICONS_BASE_URL}MapPinUnitCFocus.svg`;
export const S3_MAP_PIN_UNIT_D = `${S3_ICONS_BASE_URL}MapPinUnitD.svg`;
export const S3_MAP_PIN_UNIT_D_FOCUS = `${S3_ICONS_BASE_URL}MapPinUnitDFocus.svg`;
export const S3_MAP_PIN_UNIT_E = `${S3_ICONS_BASE_URL}MapPinUnitE.svg`;
export const S3_MAP_PIN_UNIT_E_FOCUS = `${S3_ICONS_BASE_URL}MapPinUnitEFocus.svg`;
export const S3_MAP_PIN_UNIT_F = `${S3_ICONS_BASE_URL}MapPinUnitF.svg`;
export const S3_MAP_PIN_UNIT_F_FOCUS = `${S3_ICONS_BASE_URL}MapPinUnitFFocus.svg`;
export const S3_MENU_ELLIPSIS = `${S3_ICONS_BASE_URL}MenuEllipsis.svg`;
export const S3_PLOT_LABEL_OFF = `${S3_ICONS_BASE_URL}PlotLabelOff.svg`;
export const S3_PLOT_LABEL_ON = `${S3_ICONS_BASE_URL}PlotLabelOn.svg`;
export const S3_PLOT_LABEL_LIVE = `${S3_ICONS_BASE_URL}PlotLabelLive.svg`;
export const S3_PLOT_OVERLAY = `${S3_ICONS_BASE_URL}PlotOverlay.svg`;
export const S3_PLOT_STACKED = `${S3_ICONS_BASE_URL}PlotStacked.svg`;
export const S3_PRODUCT_TCU = `${S3_ICONS_BASE_URL}ProductTCU.svg`;
export const S3_SAMPLE_TOGGLE = `${S3_ICONS_BASE_URL}SampleToggle.svg`;
export const S3_SESSIONS = `${S3_ICONS_BASE_URL}Sessions.svg`;
export const S3_SETTING_APPLICATIONS = `${S3_ICONS_BASE_URL}SettingsApplications.svg`;
export const S3_SETTINGS_GEAR = `${S3_ICONS_BASE_URL}SettingsGear.svg`;
export const S3_SETTINGS_UNIT = `${S3_ICONS_BASE_URL}SettingsUnits.svg`;
export const S3_SIDEBAR_LEFT_AXES = `${S3_ICONS_BASE_URL}SidebarLeftAxes.svg`;
export const S3_SIDEBAR_LEFT_UNITS_LIST = `${S3_ICONS_BASE_URL}SidebarLeftUnitsList.svg`;
export const S3_SIDEBAR_RIGHT_ACTIVATE = `${S3_ICONS_BASE_URL}SidebarRightActivate.svg`;
export const S3_SIDEBAR_STACKED = `${S3_ICONS_BASE_URL}SidebarStacked.svg`;
export const S3_SIDEBAR_TOP_UNITS_LIST = `${S3_ICONS_BASE_URL}SidebarTopUnitsList.svg`;
export const S3_SIGNAL_0_NONE = `${S3_ICONS_BASE_URL}Signal0None.svg`;
export const S3_SIGNAL_1_VERY_POOR = `${S3_ICONS_BASE_URL}Signal1VeryPoor.svg`;
export const S3_SIGNAL_2_POOR = `${S3_ICONS_BASE_URL}Signal2Poor.svg`;
export const S3_SIGNAL_3_AVERAGE = `${S3_ICONS_BASE_URL}Signal3Average.svg`;
export const S3_SIGNAL_4_GOOD = `${S3_ICONS_BASE_URL}Signal4Good.svg`;
export const S3_SIGNAL_5_GREAT = `${S3_ICONS_BASE_URL}Signal5Great.svg`;
export const S3_STATUS_DOT_OFF = `${S3_ICONS_BASE_URL}StatusDotOFF.svg`;
export const S3_STATUS_DOT_ON = `${S3_ICONS_BASE_URL}StatusDotON.svg`;
export const S3_STATUS_FAULT = `${S3_ICONS_BASE_URL}StatusFault.svg`;
export const S3_STATUS_NORMAL = `${S3_ICONS_BASE_URL}StatusNormal.svg`;
export const S3_STATUS_UNKNOWN = `${S3_ICONS_BASE_URL}StatusUnknown.svg`;
export const S3_STATUS_WARNING = `${S3_ICONS_BASE_URL}StatusWarning.svg`;
export const S3_TIME = `${S3_ICONS_BASE_URL}Time.svg`;
export const S3_TIME_REWIND = `${S3_ICONS_BASE_URL}TimeRewind.svg`;
export const S3_TRACK_FIRST = `${S3_ICONS_BASE_URL}TrackFirst.svg`;
export const S3_TRACK_LAST = `${S3_ICONS_BASE_URL}TrackLast.svg`;
export const S3_TRACK_NEXT = `${S3_ICONS_BASE_URL}TrackNext.svg`;
export const S3_TRACK_PAUSE = `${S3_ICONS_BASE_URL}TrackPause.svg`;
export const S3_TRACK_PLAY = `${S3_ICONS_BASE_URL}TrackPlay.svg`;
export const S3_TRACK_PREV = `${S3_ICONS_BASE_URL}TrackPrev.svg`;
export const S3_USER = `${S3_ICONS_BASE_URL}User.svg`;
export const S3_VEHICLE_AIRPLANE = `${S3_ICONS_BASE_URL}VehicleAirplane.svg`;
export const S3_VEHICLE_BUS = `${S3_ICONS_BASE_URL}VehicleBus.svg`;
export const S3_VEHICLE_CAR = `${S3_ICONS_BASE_URL}VehicleCar.svg`;
export const S3_VEHICLE_CAR_AND_PLANE = `${S3_ICONS_BASE_URL}VehicleCar+Plane.svg`;
export const S3_VEHICLE_CAR_MULTIPLE = `${S3_ICONS_BASE_URL}VehicleCarMultiple.svg`;
export const S3_VEHICLE_TUG_BASIC = `${S3_ICONS_BASE_URL}VehicleTugBasic.svg`;
export const S3_VEHICLE_TUG_LADDER = `${S3_ICONS_BASE_URL}VehicleTugLadder.svg`;
export const S3_VIEW_LIST = `${S3_ICONS_BASE_URL}ViewList.svg`;
export const S3_VIEW_TILES = `${S3_ICONS_BASE_URL}ViewTiles.svg`;
export const S3_VIEW_TRAYS = `${S3_ICONS_BASE_URL}ViewTrays.svg`;
export const S3_WIDGET_LAYOUT = `${S3_ICONS_BASE_URL}WidgetLayout.svg`;
export const S3_LOADING_SPINNER =
  "https://fleet-assets.s3.us-west-1.amazonaws.com/design/Assets/20220707+Stafl+Fleet+Logo_LogoMainV2_v20220714-ANIM.svg";

const S3_DESIGN_BASE_URL =
  "https://fleet-assets.s3.us-west-1.amazonaws.com/design/Assets/";
export const S3_LOGO = `${S3_DESIGN_BASE_URL}20220707+Stafl+Fleet+Logo_LogoMainV2.svg`;
export const S3_LOGIN_BACKGROUND = `${S3_DESIGN_BASE_URL}StaflFleetWebsiteMockup_004_Homepage-150dpi_v230918a_BrowserInner_2160x1600.jpg`;
export const ICON_PATH = "/assets/images";
export const ICON_CAR_PLANE =
  "StaflSystemsFleet_ICONSET_SideNav_ApplicationsCarPlane.svg";

// Line Chart Sidebar
export const LINECHART_SIDEBAR_WIDTH = 250;

// images
export const TCU_PRODUCT_IMAGE =
  "https://fleet-assets.s3.us-west-1.amazonaws.com/assets/20210813%2BFleet%2BManagement%2BModule.845_edit.png";
export const DEFAULT_PRODUCT_IMAGE = `${ICON_PATH}/${ICON_CAR_PLANE}`;

// LineRedux
export const DEFAULT_CHART_COLORS = [
  "#2980b9",
  "#27ae60",
  "#e67e22",
  "#c0392b",
  "#8f56a6",
  "#3498db",
  "#1abc9c",
  "#9b59b6",
  "#34495e",
];
export const VALID_Y_AXIS_SCALING_MODES = ["auto", "toZero"];
const TIME_SECOND = 1000;
const TIME_MINUTE = TIME_SECOND * 60;
const TIME_HOUR = TIME_MINUTE * 60;
const TIME_DAY = TIME_HOUR * 24;
const TIME_WEEK = TIME_DAY * 7;
const TIME_MONTH = TIME_DAY * 30;
const TIME_YEAR = TIME_DAY * 365;
export const TICK_FORMAT_STOPS = [
  {
    dtickrange: [Number.NEGATIVE_INFINITY, TIME_SECOND],
    value: "%H:%M:%S.%L\n%b %e",
  },
  { dtickrange: [TIME_SECOND, TIME_MINUTE], value: "%H:%M:%S\n%b %e" },
  { dtickrange: [TIME_MINUTE, TIME_HOUR], value: "%H:%M:%S\n%b %e" },
  { dtickrange: [TIME_HOUR, TIME_DAY], value: "%H:%M\n%b %e" },
  { dtickrange: [TIME_DAY, TIME_WEEK], value: "%H:%M\n%b %e" },
  { dtickrange: [TIME_WEEK, TIME_MONTH], value: "%b %e\n %Y" },
  { dtickrange: [TIME_MONTH, TIME_YEAR], value: "%b %e\n%Y" },
  { dtickrange: [TIME_YEAR, Number.POSITIVE_INFINITY], value: "%b %Y" },
];

// Bytes Conversions
export const BYTES_AS_KB = 1024;
export const BYTES_AS_MB = 1024 * BYTES_AS_KB;
export const BYTES_AS_GB = 1024 * BYTES_AS_MB;

export const CURSORTYPE_MEASURE = "measure";
export const CURSORTYPE_DIFF = "diff";

export const DEFAULT_LINECHART_CONFIG = {
  // scroll zoom interactions
  allowScrollZoom: true,
  allowYZoom: false,
  enableZoomConstraints: true,
  scaleExtentMin: 0.00000951292, // one year (relative to 5min)
  scaleExtentMax: 40,
  translateDistance: 200000000,
  extendYAxisBounds: true,
  yAxisScaleMode: "auto",
  zoomDebounceDelay: 20,

  // cursors
  enableMeasureCursor: true,
  enableDiffCursor: true,
  measureCursorInitiallyVisible: true,
  diffCursorInitiallyVisible: false,

  // timeselect
  enableTimeSelect: true,
  initialRangeMode: "relative", // "absolute"
  initialRelativeDuration: 5,
  initialRelativeUnit: "minute",

  // live mode
  enableLiveMode: true, // whether live mode as a feature is available
  initialLiveState: true,
  refreshRate: 1000,
};

export const DEFAULT_LINECHART_LAYOUT = {
  // full render
  width: 720,
  height: 360,
  autoFitContainer: true,
  margin: { top: 22, right: 10, bottom: 10, left: 10 },

  // axes
  // sizeYAxis: 55,
  sizeYAxis: 67,
  sizeXAxis: 25,

  // tooltip
  tooltipOffsetX: 15,
  tooltipOffsetY: 15,
  tooltipWidth: 500,
};
export const REALTIMETEXT_ALL = "All";
export const REALTIMETEXT_NONE = "None";
export const REALTIMETEXT_LIVE = "Live";
export const DRAGSCROLLDIRECTION_NONE = "none";
export const DRAGSCROLLDIRECTION_LEFT = "left";
export const DRAGSCROLLDIRECTION_RIGHT = "right";
export const TOOLTIP_DATE_FORMAT = "MMM D | hh:mm:ss a";
export const ALLOWED_ZOOM_EVENT_TYPES = ["wheel", "mousemove"];

// Map
export const DEFAULT_MAP_CENTER = { lat: 37.7749, lng: -122.4194 };
export const COORD_TOLERANCE = 0.0001;
export const MAP_EXPANSION_FACTOR = 0.003; // 0.01 default; amount to expand bounds by if focused on one point
export const MAP_TYPE_ROADMAP = "roadmap";
export const MAP_TYPE_SATELLITE = "satellite";
export const MAP_TYPE_HYBRID = "hybrid";
export const MAP_TYPE_TERRAIN = "terrain";

// TCU Data / Log Upload
export const TCUUPLOAD_RESPONSE_ACK = 1; // 0x01
export const TCUUPLOAD_RESULT_INVALID = 1; // 0x01
export const TCUUPLOAD_RESULT_VALID = 2; // 0x02
export const TCUUPLOAD_RESULT_BUSY = 3; // 0x03
export const TCUUPLOAD_RESULT_CANCELLED = 4; //0x04
export const TCUUPLOAD_RESULT_FAILED = 5; // 0x05
export const TCUUPLOAD_RESULT_FINISHED = 6; // 0x05
export const TCUUPLOAD_RESULT_TRANSLATIONS = {
  [TCUUPLOAD_RESULT_INVALID]: "INVALID",
  [TCUUPLOAD_RESULT_VALID]: "VALID",
  [TCUUPLOAD_RESULT_BUSY]: "BUSY",
  [TCUUPLOAD_RESULT_CANCELLED]: "CANCELLED",
  [TCUUPLOAD_RESULT_FAILED]: "FAILED",
  [TCUUPLOAD_RESULT_FINISHED]: "FINISHED",
};
export const TCU_CMD_ID_GENERAL = "16";
export const TCU_CMD_ID_NETWORK = "17";
export const TCU_CMD_ID_STORAGE = "18";
export const TCU_CMD_ID_EVENT = "19";
// DEPRECATED
export const TCUUPLOAD_CMD_QUERYLOGINFO = "32";
export const TCUUPLOAD_CMD_START = "34";
export const TCUUPLOAD_CMD_CANCEL = "15";
export const TCUUPLOAD_RES_ACK = "1";
export const TCUUPLOAD_RES_RESPONSE = "2";
export const TCUUPLOAD_RES_CHECKNUMPAGES = "3";
export const TCUUPLOAD_RES_COMPLETE = "4";
// END DEPRECATED

// Application Settings
// export const ENABLE_APPLICATION_SETTINGS_FIRMWARE_SECTION = true;

// Parameters
export const CAPTURE_RATES_LABEL = "capture_rates";
export const MESSAGE_FILTERS_LABEL = "can_cap_filters";
export const RATES_GPS_LABEL = "gps";
export const RATES_RSSI_LABEL = "rssi";
export const RATES_CHKMQ_LABEL = "chk_mq";

/**
 * From previous constants.js
 * ==========================
 */

export const GRID_HEIGHT = 70;
export const MODAL_SIZE = "md";

class EventsInfo {
  constructor(idx, name, icon) {
    this.idx = idx;
    this.name = name;
    this.icon = icon;
  }
  getIdx() {
    return this.idx;
  }
  getName() {
    return this.name;
  }
  getIcon() {
    return this.icon;
  }
}

export const TCU_EVENTS = [
  new EventsInfo(1, "Boot", S3_EVENT_POWER_ON),
  new EventsInfo(2, "UTC Set", S3_EVENT_UTC_SET),
  new EventsInfo(5, "Last Gasp", S3_EVENT_LAST_GASP),
  new EventsInfo(7, "Power Loss", S3_EVENT_UNKNOWN),
  new EventsInfo(17, "Fleet Connected", S3_EVENT_FLEET_CONNECT),
  new EventsInfo(18, "Fleet Disconnected", S3_EVENT_FLEET_DISCONNECT),
  new EventsInfo(32, "Custom Event", S3_EVENT_CUSTOM),
  new EventsInfo(80, "Stg. Download Start", S3_EVENT_OTA_START),
  new EventsInfo(82, "Stg. Download Pass", S3_EVENT_OTA_DOWNLOADED),
  new EventsInfo(83, "Stg. Download Fail", S3_EVENT_OTA_DOWNLOADED),
  new EventsInfo(84, "Stg. Cancel", S3_EVENT_OTA_CANCEL),
  new EventsInfo(86, "Stg. Error", S3_EVENT_OTA_ERROR),
  new EventsInfo(96, "Stg. Checksum Start", S3_EVENT_OTA_CHECK),
  new EventsInfo(97, "Stg. Checksum Pass", S3_EVENT_OTA_CHECK),
  new EventsInfo(98, "Stg. Checksum Fail", S3_EVENT_OTA_CHECK),
  new EventsInfo(99, "Stg. Conditions Set", S3_EVENT_OTA_INSTALL),
  new EventsInfo(100, "Stg. Conditions Met", S3_EVENT_OTA_INSTALL),
  new EventsInfo(101, "Stg. Install Start", S3_EVENT_OTA_START),
  new EventsInfo(102, "Stg. Install Pass", S3_EVENT_OTA_INSTALL),
  new EventsInfo(103, "Stg. Install Fail", S3_EVENT_OTA_INSTALL),
  new EventsInfo(117, "Stg. Install Ext Start", S3_EVENT_OTA_START),
  new EventsInfo(118, "Stg. Install Ext Pass", S3_EVENT_OTA_INSTALL),
  new EventsInfo(119, "Stg. Install Ext Fail", S3_EVENT_OTA_INSTALL),
  new EventsInfo(128, "Stg. Install Finish", S3_EVENT_OTA_FINISH),
];

export const getInfoForEvent = (event_id, customEventLabel = null) => {
  let info = { title: "Unknown Event", icon: S3_EVENT_UNKNOWN };
  let event = TCU_EVENTS.find((instance) => instance.getIdx() === event_id);
  if (event) {
    info.title = event.getName();
    info.icon = event.getIcon();
    if (event.getIdx() === 32 && customEventLabel)
      info.title = customEventLabel;
  }

  return info;
};
