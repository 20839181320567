import Plot from "react-plotly.js";
import styled from "styled-components";
import { withSize } from "react-sizeme";
import { useDeviceStatuses } from "features/units/hooks";

const PieChartWrapper = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const PieChartFigure = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const PieChartLegend = styled.div`
  flex: 1;
`;

const LegendItem = styled.div`
  max-width: 150px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;

  & span.count {
    font-weight: bold;
  }
`;

const DashboardStatusPieChartWidget = ({ size, getSettingsField }) => {
  const deviceStatuses = useDeviceStatuses();
  const liveCount = deviceStatuses["live"];
  const lastDayCount = deviceStatuses["lastDay"];
  const lastWeekCount = deviceStatuses["lastWeek"];
  const lastMonthCount = deviceStatuses["lastMonth"];
  const overAMonthCount = deviceStatuses["overAMonth"];

  // widget settings
  const livePieceColor = getSettingsField("live_color");
  const lastDayPieceColor = getSettingsField("last_day_color");
  const lastWeekPieceColor = getSettingsField("last_week_color");
  const lastMonthPieceColor = getSettingsField("last_month_color");
  const overAMonthPieceColor = getSettingsField("over_a_month_color");

  const dataColors = [
    `rgb(${livePieceColor?.r ?? 127},${livePieceColor?.g ?? 127},${
      livePieceColor?.b ?? 127
    })`,
    `rgb(${lastDayPieceColor?.r ?? 127},${lastDayPieceColor?.g ?? 127},${
      lastDayPieceColor?.b ?? 127
    })`,
    `rgb(${lastWeekPieceColor?.r ?? 127},${lastWeekPieceColor?.g ?? 127},${
      lastWeekPieceColor?.b ?? 127
    })`,
    `rgb(${lastMonthPieceColor?.r ?? 127},${lastMonthPieceColor?.g ?? 127},${
      lastMonthPieceColor?.b ?? 127
    })`,
    `rgb(${overAMonthPieceColor?.r ?? 127},${overAMonthPieceColor?.g ?? 127},${
      overAMonthPieceColor?.b ?? 127
    })`,
  ];

  const chartConfig = {
    staticPlot: true,
    responsive: true,
  };

  // keeps num value contained between min and max values
  const clamp = (num, min, max) => Math.min(Math.max(num, min), max);
  const pieWidth = clamp(size.width * 0.3, 200, 230);
  const chartLayout = {
    ...{
      height: 250,
      width: 250,
      hovermode: false,
      plot_bgcolor: "black",
      paper_bgcolor: "transparent",
      margin: {
        b: 10,
        l: 10,
        r: 10,
        t: 10,
      },
      showlegend: false,
    },
    width: pieWidth,
    height: pieWidth,
    annotations: [
      {
        ...{
          font: {
            size: 16,
          },
          showarrow: false,
          text: "Fleet Status",
          x: 0.24,
          y: 0.5,
        },
        font: {
          size: 0.1 * pieWidth,
        },
        text: "Status",
        x: 0.5,
        y: 0.5,
      },
    ],
  };
  const chartData = [
    {
      ...{
        values: [
          liveCount,
          lastDayCount,
          lastWeekCount,
          lastMonthCount,
          overAMonthCount,
        ],
        hole: 0.8,
        type: "pie",
        marker: { colors: dataColors },
        textinfo: "none",
      },
    },
  ];
  const legendItems = [
    {
      label: "Live",
      color: dataColors[0],
      count: liveCount,
    },
    {
      label: "Last Day",
      color: dataColors[1],
      count: lastDayCount,
    },
    {
      label: "Last Week",
      color: dataColors[2],
      count: lastWeekCount,
    },
    {
      label: "Last Month",
      color: dataColors[3],
      count: lastMonthCount,
    },
    {
      label: "Over A Month",
      color: dataColors[4],
      count: overAMonthCount,
    },
  ];
  return (
    <PieChartWrapper>
      <PieChartFigure>
        <Plot layout={chartLayout} config={chartConfig} data={chartData} />
      </PieChartFigure>
      <PieChartLegend>
        {legendItems.map((item) => (
          <LegendItem key={item.label}>
            <span>{item.label}</span>
            <span className="count" style={{ color: item.color }}>
              {item.count}
            </span>
          </LegendItem>
        ))}
      </PieChartLegend>
    </PieChartWrapper>
  );
};

export const DashboardStatusPieChart = withSize()(
  DashboardStatusPieChartWidget
);
