import { useState, useEffect } from "react";
import styled, { useTheme } from "styled-components";
import Plot from "react-plotly.js";
import { withSize } from "react-sizeme";
import { Spinner } from "common/Spinner";
import { useGetCustomerApplicationDataUsageQuery } from "features/applications/redux";
import { useCustomerId } from "features/customers/hooks";
import { BYTES_AS_MB, BYTES_AS_GB } from "utils/defines";
import {
  getBytesAsGB,
  getBytesAsKB,
  getBytesAsMB,
  getDateFormattedString,
} from "utils";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  container-type: inline-size;
`;

const DashboardDataUsageBarChartWidget = ({ size, getSettingsField }) => {
  const customerID = useCustomerId();
  const [isWidgetLoaded, setIsWidgetLoaded] = useState(false);

  // plot settings
  const [plotData, setPlotData] = useState([]);
  const [plotTitle, setPlotTitle] = useState("");
  const [plotFontFamily, setPlotFontFamily] = useState("");
  const [plotXRange, setPlotXRange] = useState([]);
  const [plotYRange, setPlotYRange] = useState([0, 1]);
  const [plotYTitle, setPlotYTitle] = useState("Bytes");

  // widget settings
  const widgetTitle = getSettingsField("widgetTitle", "");
  const widgetFontFamily = getSettingsField("widgetFontFamily");
  const widgetMarkerColor = getSettingsField("widgetMarkerColor", "");

  const theme = useTheme();

  const {
    data: qCustomerApplicationDataUsage,
    isLoading: qCustomerApplicationDataUsageLoading,
  } = useGetCustomerApplicationDataUsageQuery(
    {
      customer_id: customerID,
      time_range: "per_last_month",
    },
    { skip: customerID == null }
  );

  useEffect(() => {
    setIsWidgetLoaded(!qCustomerApplicationDataUsageLoading);
    if (!isWidgetLoaded) return;

    // populate and add as map keys, the last 30 days as date strings
    let perDayPastMonth = {};
    const today = new Date();
    for (let i = 0; i < 30; i++) {
      let _date = new Date();
      _date.setDate(today.getDate() - i);
      perDayPastMonth[getDateFormattedString(_date)] = 0;
    }

    // update last 30 day map of total data usage per day in bytes
    let biggestData = 0;
    qCustomerApplicationDataUsage?.forEach((item) => {
      item.hologramUsage?.forEach((subItem) => {
        if (subItem.date in perDayPastMonth) {
          perDayPastMonth[subItem.date] += subItem.bytes;
          if (perDayPastMonth[subItem.date] > biggestData)
            biggestData = perDayPastMonth[subItem.date];
        }
      });
    });

    // convert bytes data to represent in displayable units (KB,MB,GB)
    if (biggestData < BYTES_AS_MB) {
      setPlotYTitle("Bytes (KB)");
      setPlotYRange([0, getBytesAsKB(biggestData) + 1]);
      Object.keys(perDayPastMonth).forEach((item) => {
        perDayPastMonth[item] = getBytesAsKB(perDayPastMonth[item]).toFixed(2);
      });
    } else if (biggestData >= BYTES_AS_MB && biggestData < BYTES_AS_GB) {
      setPlotYTitle("Bytes (MB)");
      setPlotYRange([0, getBytesAsMB(biggestData) + 1]);
      Object.keys(perDayPastMonth).forEach((item) => {
        perDayPastMonth[item] = getBytesAsMB(perDayPastMonth[item]).toFixed(2);
      });
    } else if (biggestData >= BYTES_AS_GB) {
      setPlotYTitle("Bytes (GB)");
      setPlotYRange([0, getBytesAsGB(biggestData) + 1]);
      Object.keys(perDayPastMonth).forEach((item) => {
        perDayPastMonth[item] = getBytesAsGB(perDayPastMonth[item]).toFixed(2);
      });
    }

    const daysXRange = Object.keys(perDayPastMonth);

    // update font family
    if (widgetFontFamily === "2") {
      setPlotFontFamily("Rubik");
    } else if (widgetFontFamily === "3") {
      setPlotFontFamily("PilatWide");
    }

    setPlotTitle(widgetTitle ?? "Data Usage Per Day Past Month");
    setPlotXRange([daysXRange[29], daysXRange[0]]);
    setPlotData([
      {
        x: daysXRange,
        y: Object.values(perDayPastMonth),
        type: "bar",
        orientation: "horizontal",
        marker: {
          color:
            widgetMarkerColor === ""
              ? theme.themePrimary
              : `rgb(${widgetMarkerColor.r},${widgetMarkerColor.g},${widgetMarkerColor.b})`,
        },
      },
    ]);
  }, [
    qCustomerApplicationDataUsageLoading,
    isWidgetLoaded,
    qCustomerApplicationDataUsage,
    theme,
    widgetFontFamily,
    widgetTitle,
  ]);

  if (!isWidgetLoaded) return <Spinner />;

  return (
    <Wrapper>
      <Plot
        style={{
          position: "relative",
          display: "inline-block",
          width: "100%",
          height: "100%",
        }}
        data={plotData}
        layout={{
          font: {
            family: `${plotFontFamily}`,
          },
          title: {
            text: `${plotTitle}`,
          },
          xaxis: {
            title: {
              text: "Dates",
              standoff: 15,
            },
            type: "date",
            tickmode: "linear",
            range: plotXRange,
          },
          yaxis: {
            title: {
              text: `${plotYTitle}`,
              standoff: 15,
            },
            range: plotYRange,
            automargin: true,
          },
          width: size.width,
          height: size.height,
          responsive: true,
          dragmode: false,
        }}
        config={{
          doubleClick: false,
        }}
      />
    </Wrapper>
  );
};

export const DashboardDataUsageBarChart = withSize()(
  DashboardDataUsageBarChartWidget
);
