import Plot from "react-plotly.js";
import { withSize } from "react-sizeme";
import { useGetCustomerApplicationEnergyAggregationQuery } from "features/applications/redux";
import { Spinner } from "common/Spinner";
import { useEffect, useState } from "react";
import styled, { useTheme } from "styled-components";
import { getDateFormattedString, getPastDatesByNumberOfDays } from "utils";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  container-type: inline-size;
`;

const TotalEnergyUsagePerDayBarGraph = ({
  applicationID,
  instanceSignals,
  dateRange = [],
  numOfDays,
  aggregationMethod,
  title = "",
  yTitle = "",
  fontFamily = "",
  markerColor,
  size,
  theme,
}) => {
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  const [plotData, setPlotData] = useState([]);
  const [plotXRange, setPlotXRange] = useState([]);
  const [plotYRange, setPlotYRange] = useState([]);
  const [plotFontFamily, setPlotFontFamily] = useState("");

  // loading data
  const {
    data: qEnergyAggregationData,
    isLoading: isQEnergyAggregationDataLoading,
  } = useGetCustomerApplicationEnergyAggregationQuery(
    {
      application_id: applicationID,
      signal_ids: instanceSignals,
      start: startDate,
      end: endDate,
      days: numOfDays,
    },
    {
      skip:
        applicationID === null ||
        instanceSignals.length !== 2 ||
        startDate === "" ||
        endDate === "",
    }
  );

  useEffect(() => {
    if (dateRange.length > 0) {
      setStartDate(dateRange[0]);
      setEndDate(dateRange[dateRange.length - 1]);
    }
  }, [dateRange]);

  useEffect(() => {
    if (!isQEnergyAggregationDataLoading) {
      let minData = 0;
      let maxData = 0;
      let binData = [];
      dateRange.forEach((day) => {
        let dataValue = 0;

        if (qEnergyAggregationData) {
          const dayData = qEnergyAggregationData[day];
          if (dayData && aggregationMethod === "total_energy_usage") {
            dataValue =
              dayData.energyUsage ?? dayData?.cache_value?.M?.energyUsage?.N;
          } else if (dayData && aggregationMethod === "total_charge_usage") {
            dataValue =
              dayData.totalCharge ?? dayData?.cache_value?.M?.totalCharge?.N;
          } else if (dayData && aggregationMethod === "total_discharge_usage") {
            dataValue =
              dayData.totalDischarge ??
              dayData?.cache_value?.M?.totalDischarge?.N;
          }
        }

        if (dataValue > maxData) maxData = Number(dataValue).toFixed(2) + 1;
        if (dataValue < minData) minData = Number(dataValue).toFixed(2) - 1;

        binData.push(Number(dataValue).toFixed(2));
      });

      // update font family
      if (fontFamily === "2") {
        setPlotFontFamily("Rubik");
      } else if (fontFamily === "3") {
        setPlotFontFamily("PilatWide");
      }

      setPlotData([
        {
          x: dateRange,
          y: binData,
          type: "bar",
          orientation: "horizontal",
          marker: {
            color:
              markerColor === ""
                ? theme.themePrimary
                : `rgb(${markerColor.r},${markerColor.g},${markerColor.b})`,
          },
        },
      ]);

      setPlotXRange([startDate, endDate]);
      setPlotYRange([minData, maxData]);
    }
  }, [qEnergyAggregationData, isQEnergyAggregationDataLoading]);

  if (isQEnergyAggregationDataLoading) return <Spinner />;

  return (
    <Plot
      style={{
        position: "relative",
        display: "inline-block",
        width: "100%",
        height: "100%",
      }}
      data={plotData}
      layout={{
        title: `${title}`,
        font: {
          family: `${plotFontFamily}`,
        },
        xaxis: {
          title: {
            text: "Dates",
            standoff: 15,
          },
          type: "date",
          tickmode: "linear",
          range: plotXRange,
        },
        yaxis: {
          title: {
            text: `${yTitle}`,
            standoff: 15,
          },
          range: plotYRange,
          automargin: true,
        },
        width: size.width,
        height: size.height,
        responsive: true,
        dragmode: false,
      }}
      config={{
        doubleClick: false,
      }}
    />
  );
};

const DashboardEnergyAggregationWidget = ({
  size,
  instance_signals,
  getSettingsField,
}) => {
  const instanceSignalIds = instance_signals.map(
    (instance) => instance.signal_id
  );
  const application_id = instance_signals[0]?.signal.product_id;
  const theme = useTheme();

  const [dateRange, setDateRange] = useState([]);

  const widgetTitle = getSettingsField("widgetTitle", "");
  const widgetFontFamily = getSettingsField("widgetFontFamily", "1");
  const widgetNumOfDays = parseInt(getSettingsField("numDays", "2"));
  const widgetGraphType = getSettingsField("widgetGraphType", "1");
  const widgetGraphColor = getSettingsField("widgetGraphColor", "");

  useEffect(() => {
    const numOfDates = getPastDatesByNumberOfDays(widgetNumOfDays);
    setDateRange(numOfDates);
  }, [widgetNumOfDays]);

  const renderDashboardEnergyAggregationGraphByType = () => {
    switch (widgetGraphType) {
      case "1":
        return (
          <TotalEnergyUsagePerDayBarGraph
            applicationID={application_id}
            instanceSignals={instanceSignalIds}
            dateRange={dateRange}
            title={
              widgetTitle.trim() === ""
                ? "Total Energy Usage per Day"
                : widgetTitle
            }
            yTitle={"Energy (kWh)"}
            fontFamily={widgetFontFamily}
            numOfDays={widgetNumOfDays}
            aggregationMethod={"total_energy_usage"}
            markerColor={widgetGraphColor}
            theme={theme}
            size={size}
          />
        );
      case "2":
        return (
          <TotalEnergyUsagePerDayBarGraph
            applicationID={application_id}
            instanceSignals={instanceSignalIds}
            dateRange={dateRange}
            title={
              widgetTitle.trim() === ""
                ? "Total Charge Usage per Day"
                : widgetTitle
            }
            yTitle={"Charge (kWh)"}
            fontFamily={widgetFontFamily}
            numOfDays={widgetNumOfDays}
            aggregationMethod={"total_charge_usage"}
            markerColor={widgetGraphColor}
            theme={theme}
            size={size}
          />
        );
      case "3":
        return (
          <TotalEnergyUsagePerDayBarGraph
            applicationID={application_id}
            instanceSignals={instanceSignalIds}
            dateRange={dateRange}
            title={
              widgetTitle.trim() === ""
                ? "Total Discharge Usage per Day"
                : widgetTitle
            }
            yTitle={"Charge (kWh)"}
            fontFamily={widgetFontFamily}
            numOfDays={widgetNumOfDays}
            aggregationMethod={"total_discharge_usage"}
            markerColor={widgetGraphColor}
            theme={theme}
            size={size}
          />
        );
      default:
        return <>Coming Soon...</>;
    }
  };

  return (
    <>
      <Wrapper>{renderDashboardEnergyAggregationGraphByType()}</Wrapper>
    </>
  );
};

export const DashboardEnergyAggregation = withSize()(
  DashboardEnergyAggregationWidget
);
