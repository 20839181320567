import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { BASE_API_URL } from "config";
import storage from "utils/storage";

const baseApplicationsQuery = fetchBaseQuery({
  baseUrl: `${BASE_API_URL}/api/`,
  prepareHeaders: (headers) => {
    const accessToken = storage.getAccessToken();
    if (accessToken) headers.set("Authorization", `Bearer ${accessToken}`);
    return headers;
  },
});

export const applicationsApi = createApi({
  reducerPath: "applicationsApi",
  baseQuery: baseApplicationsQuery,
  tagTypes: [
    "Application",
    "Applications",
    "ApplicationPairs",
    "SignalDistributions",
    "ApplicationDataUsage",
    "ApplicationEnergyUsage",
  ],
  endpoints: (builder) => ({
    getParametersForProduct: builder.query({
      query: (productId) => `/parameters/product?product_id=${productId}`,
      providesTags: ["Parameters"],
    }),
    updateProductParameter: builder.mutation({
      query: (product_parameter) => ({
        url: "/parameters/product",
        method: "PUT",
        body: { product_parameter },
      }),
      invalidatesTags: ["Parameters"],
    }),
    getProductSettingsForCustomer: builder.query({
      query: (customerId) => `/products/settings?customer_id=${customerId}`,
    }),
    getApplicationsByFilter: builder.query({
      query: (args) => {
        const { customer_id } = args || {};
        const params = {};
        if (customer_id !== undefined) params.customer_id = customer_id;
        return {
          url: `/products`,
          params: params,
          provideTags: ["Applications"],
        };
      },
    }),
    getApplicationByID: builder.query({
      query: (id) => ({
        url: `/products/${id}`,
        providesTags: ["Application"],
      }),
    }),
    createApplication: builder.mutation({
      query: (args) => ({
        url: "/products",
        method: "POST",
        body: args,
      }),
      invalidatesTags: ["Applications"],
    }),
    updateApplication: builder.mutation({
      query: (args) => ({
        url: `/products/${args.fields.id}`,
        method: "PUT",
        body: { ...args },
      }),
      invalidatesTags: ["Application"],
    }),
    getCustomerApplicationPairingHistory: builder.query({
      query: (args) => {
        const { customer_id } = args || {};
        const params = {};
        if (customer_id !== undefined) params.customer_id = customer_id;
        return {
          url: `/products/stats/tcu-pairs-created`,
          params: params,
          provideTags: ["ApplicationPairs"],
        };
      },
    }),
    getCustomerApplicationSignalDistribution: builder.query({
      query: (args) => {
        const { customer_id, signal_ids } = args || {};
        const params = {};
        if (customer_id !== undefined) params.customer_id = customer_id;
        if (signal_ids !== undefined) params.signal_ids = signal_ids.join(",");
        return {
          url: `/products/stats/signal-distribution`,
          params: params,
          provideTags: ["SignalDistributions"],
        };
      },
    }),
    getCustomerApplicationDataUsage: builder.query({
      query: (args) => {
        const params = args || {};
        return {
          url: `/products/stats/hologram-data-usage`,
          params: params,
          provideTags: ["ApplicationDataUsage"],
        };
      },
    }),
    getCustomerApplicationEnergyAggregation: builder.query({
      query: (args) => {
        const { application_id, signal_ids, start, end, days } = args || {};
        const params = {};
        if (application_id !== undefined)
          params.application_id = application_id;
        if (signal_ids !== undefined) params.signal_ids = signal_ids.join(",");
        if (start !== undefined) params.start = start;
        if (end !== undefined) params.end = end;
        if (days !== undefined) params.days = days;
        return {
          url: `/products/stats/energy-aggregation`,
          params: params,
          provideTags: ["ApplicationEnergyUsage"],
        };
      },
    }),
    getSignalUnits: builder.query({
      query: () => "/signals/units",
    }),
    getSignalDataTypes: builder.query({
      query: () => "/signals/data_types",
    }),
    getSignalTypes: builder.query({
      query: () => "/signals/types",
    }),
    getSignalsForCustomer: builder.query({
      query: (customerId) => `/signals?customer_id=${customerId}`,
      providesTags: ["Signals"],
    }),
    uploadDBCForApplication: builder.mutation({
      query: (args) => ({
        url: `/signals/dbc/`,
        method: "POST",
        body: args,
      }),
      invalidatesTags: ["Signals"],
    }),
    updateSignal: builder.mutation({
      query: (signal) => ({
        url: `/signals/`,
        method: "PUT",
        body: signal,
      }),
      invalidatesTags: ["Signals"],
    }),
  }),
});

export const {
  useGetSignalUnitsQuery,
  useGetSignalDataTypesQuery,
  useGetSignalTypesQuery,
  useGetSignalsForCustomerQuery,
  useUploadDBCForApplicationMutation,
  useUpdateSignalMutation,
  useGetParametersForProductQuery,
  useUpdateProductParameterMutation,
  useGetProductSettingsForCustomerQuery,
  useGetApplicationByIDQuery,
  useGetApplicationsByFilterQuery,
  useCreateApplicationMutation,
  useUpdateApplicationMutation,
  useGetCustomerApplicationPairingHistoryQuery,
  useGetCustomerApplicationSignalDistributionQuery,
  useGetCustomerApplicationDataUsageQuery,
  useGetCustomerApplicationEnergyAggregationQuery,
} = applicationsApi;
