import { PubSub } from "aws-amplify";

import { widgetsList, widgetTypesList } from "../config/widgetDefines";

const sortByWidgetName = (a, b) => {
  if (a.displayName < b.displayName) return -1;
  if (a.displayName > b.displayName) return 1;
  return 0;
};

export const useAvailableWidgetsOfType = (widgetTypeName) => {
  const availableWidgets = Object.keys(widgetsList).map(
    (widgetName) => widgetsList[widgetName]
  );

  const validWidgetTypes = [widgetTypesList.all, widgetTypeName];
  const validWidgets = availableWidgets
    ? availableWidgets.filter((widget) =>
        validWidgetTypes.includes(widget.widget_type)
      )
    : [];
  const sortedWidgets = validWidgets.sort(sortByWidgetName);
  return sortedWidgets;
};

export const useMqttSubscription = ({
  topic,
  onMessageReceived = () => {},
  onMessageError = (error) => console.error(error),
  onSubscriptionComplete = () => console.log("Done"),
}) => {
  // connect to MQTT
  const subscribeToMqtt = (topic) => () => {
    console.log(`subscribing to ${topic}`);
    subscription = PubSub.subscribe(topic).subscribe({
      next: onMessageReceived,
      error: onMessageError,
      complete: onSubscriptionComplete,
    });
  };

  // disconnect
  const unsubscribeFromMqtt = () => {
    console.log(`unsubscribe from mqtt`);
    subscription.unsubscribe();
  };

  // perform subscription
  let subscription = {
    subscribe: subscribeToMqtt(topic),
    unsubscribe: unsubscribeFromMqtt,
  };

  return subscription;
};
