import SettingsField from "features/widgets/widgetlibrary/SettingsField";
import { ColorPicker } from "antd";

export const DashboardDataUsageBarChartSettings = ({
  changeSettings,
  getField,
}) => {
  return (
    <div>
      <SettingsField label="Title">
        <input
          value={getField("widgetTitle", "")}
          onChange={(e) => changeSettings("widgetTitle", e.target.value)}
        />
      </SettingsField>
      <SettingsField label="Font-Family">
        <select
          value={getField("widgetFontFamily", "1")}
          onChange={(e) => changeSettings("widgetFontFamily", e.target.value)}
        >
          <option value="1">Default</option>
          <option value="2">Rubik</option>
          <option value="3">PilatWide</option>
        </select>
      </SettingsField>
      <SettingsField label="Marker Color">
        <ColorPicker
          format="hex"
          defaultValue={getField("widgetMarkerColor")}
          onChange={(e) => {
            const { metaColor } = e;
            const rgb = {
              r: (metaColor?.r).toFixed(0),
              g: (metaColor?.g).toFixed(0),
              b: (metaColor?.b).toFixed(0),
            };
            changeSettings("widgetMarkerColor", rgb);
          }}
          getPopupContainer={(triggerNode) => {
            return triggerNode.parentNode;
          }}
        />
      </SettingsField>
    </div>
  );
};
