import { useState, useEffect } from "react";
import * as SETTINGS from "../widgetlibrary";

export const WidgetSettings = ({ widgetInfo, settings, updateSettings }) => {
  const [settingsState, setSettingsState] = useState(JSON.parse(settings));
  useEffect(() => {
    updateSettings(JSON.stringify(settingsState));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [settingsState]);

  const changeSettings = (field, newValue) => {
    setSettingsState((oldState) => {
      let newState = { ...oldState };
      newState[field] = newValue;
      return newState;
    });
  };

  const getField = (field, def) => {
    if (settingsState && settingsState[field]) {
      return settingsState[field];
    }
    return def;
  };

  // Make sure your widget settings file follows the naming
  // convention of widgetNameSettings.js for it to render
  const getSettings = () => {
    const Settings = SETTINGS[widgetInfo?.componentName + "Settings"];
    if (!Settings) {
      return null;
    }
    const oldProps = { settings, updateSettings };
    return Settings ? (
      <Settings
        {...oldProps}
        getField={getField}
        changeSettings={changeSettings}
      />
    ) : null;
  };
  if (!getSettings()) {
    return null;
  }
  return <div>{getSettings()}</div>;
};
