import { useState, useEffect } from "react";
import { useTheme } from "styled-components";
import Plot from "react-plotly.js";
import { withSize } from "react-sizeme";

import { productSNDataService } from "features/units/services";
import { WidgetWrapper, WidgetTitle, WidgetBody } from "../TileComponents";

import { useCustomerId } from "features/customers/hooks/customersHooks";
import { useGetApplicationsByFilterQuery } from "features/applications/redux";

/** convert a given 0-based month index to the string abbreviation */
const getMonthNameFromNumber = (num) => {
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  return months[num - 1];
};

export const DashboardSimpleChart = ({
  instance_signals,
  getSettingsField,
  size,
}) => {
  // init - hooks
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const customerId = useCustomerId();
  const { data: allProducts } = useGetApplicationsByFilterQuery({
    customer_id: customerId,
  });
  const product_ids = allProducts?.length ? allProducts.map((p) => p.id) : [];
  let signal_id;

  const numMonths = parseInt(getSettingsField("numMonths", "0"));
  const mode = getSettingsField("aggregationMode", "average");

  const theme = useTheme();
  const graphColor = theme.themeSecondary;

  // declare data operation
  const fetchData = async () => {
    // get results
    if (!signal_id) return;
    let results = await productSNDataService.GetAggregationForSignalOverTime({
      product_ids,
      signal_id,
      numMonths,
      mode,
    });
    // if it comes back as an error, fail silently for no
    if (results["error"]) results = [];

    const selectedType = getSettingsField("chartType", "scatter");
    const chartModeSettings =
      selectedType === "scatter"
        ? { type: "scatter", mode: "lines+markers" }
        : { type: "bar" };

    // format results
    results = results.slice(-numMonths);
    let formattedChartData = [
      {
        x: results.map((r) => {
          const yearNum = r.bin_month.split("-")[0];
          const monthNum = r.bin_month.split("-")[1];
          const monthName = getMonthNameFromNumber(monthNum);
          return `${monthName} ${yearNum.slice(-2)}`;
        }),
        y: results.map((r) => r.value),
        marker: {
          color: graphColor,
        },
        ...chartModeSettings,
      },
    ];

    // display results
    setData(formattedChartData);
    setIsLoading(false);
  };

  // fetch data on mount
  useEffect(() => {
    fetchData();
  }, []);

  // exit if no signal
  if (!instance_signals.length) return null;

  // assumes only one signal allowed

  // extract the InstanceSignal from the first (only) one
  const { IS } = instance_signals[0];
  signal_id = IS.signal.id;

  let config = { staticPlot: true };
  let layout = {
    responsive: true,
    margin: {
      b: 100,
      l: 50,
      r: 50,
      t: 20,
    },
    width: size.width,
    height: size.height,
  };

  // format for display
  const signalName = IS.getSignalName();
  const settingsTitle = getSettingsField("widgetTitle");
  const displayTitle = settingsTitle
    ? settingsTitle
    : `${mode.charAt(0).toUpperCase()}${mode
        .slice(1)
        .toLowerCase()} ${signalName} per month`;

  return (
    <WidgetWrapper style={{ marginLeft: "20px" }}>
      <WidgetTitle>{displayTitle}</WidgetTitle>
      <WidgetBody>
        {isLoading ? (
          "Loading..."
        ) : (
          <Plot
            style={{
              position: "relative",
              display: "inline-block",
              width: "100%",
              height: "100%",
            }}
            layout={layout}
            config={config}
            useResizeHandler={true}
            data={data}
          />
        )}
      </WidgetBody>
    </WidgetWrapper>
  );
};

export const SimpleChart = withSize({ monitorHeight: true })(
  DashboardSimpleChart
);
