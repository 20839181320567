import { useSelector } from "react-redux";
import { SizeMe } from "react-sizeme";
import { WidgetWrapper, WidgetBody } from "../TileComponents";
import { TextBox } from "../TextDisplay/TextDisplay";
import _ from "lodash";

// SignalAggregator (and ProductCounter) is the updated version of FleetwideText

export const SignalAggregator = ({
  instance_signals,
  getSettingsField,
  isMapModalLayout, // Widget
}) => {
  // widget settings
  const aggregationMode = getSettingsField("aggregationMode", "total");

  // redux data
  const allSNData = useSelector((state) => state.productSNData.dataHistory);

  // react hooks this pattern?
  if (!instance_signals.length)
    return (
      <WidgetWrapper>
        <WidgetBody>No signals selected</WidgetBody>
      </WidgetWrapper>
    );

  // from here, assume at most one valid signal selected

  // grab the InstanceSignal attached
  const selectedSignal = instance_signals[0].IS;
  const selectedSignalId = selectedSignal.signal.id;
  const signalUnit = selectedSignal.getUnitSymbol();

  const widgetTitle =
    getSettingsField("widgetTitle", "") || selectedSignal.getSignalName(); // todo: can this be an instance method?

  // filter all data related to selected signal
  // let relatedData = allSNData.filter(datum => datum.signal_id === selectedSignalId);
  let relatedData = Object.entries(allSNData).reduce(
    (allData, [_productSNId, dataArray]) => {
      const filteredDataArray = dataArray[selectedSignalId];
      return filteredDataArray && filteredDataArray.length
        ? _.concat(allData, filteredDataArray)
        : allData;
    },
    []
  );

  // perform the calculation
  let displayValue, calculation;
  if (relatedData.length && aggregationMode === "total") {
    calculation = relatedData.reduce(
      (result, datum) => (result += datum.data_value),
      0
    );
  } else if (relatedData.length && aggregationMode === "average") {
    calculation =
      relatedData.reduce((result, datum) => (result += datum.data_value), 0) /
      relatedData.length;
  }
  if (calculation)
    displayValue = (
      <>
        {calculation.toFixed(selectedSignal.getPrecision())}{" "}
        <span className="signal-unit">{signalUnit}</span>
      </>
    );

  // if there was a calculation, display it properly
  const result = displayValue;

  return (
    <SizeMe monitorHeight>
      {({ size }) => (
        <WidgetWrapper>
          <WidgetBody>
            <TextBox
              value={result}
              title={widgetTitle}
              size={size}
              isMapModalLayout={isMapModalLayout}
            />
          </WidgetBody>
        </WidgetWrapper>
      )}
    </SizeMe>
  );
};
