import { useEffect, useRef } from "react";

export const useInterval = (callback, delay) => {
  const savedCallback = useRef();
  const timeoutId = useRef();
  const tick = () => {
    if (savedCallback.current) {
      savedCallback.current();
      timeoutId.current = setTimeout(tick, delay);
    }
  };

  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  useEffect(() => {
    if (delay !== null) {
      tick();
      return () => {
        clearTimeout(timeoutId.current);
      };
    }
  }, [delay]);

  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.visibilityState === "visible") {
        clearTimeout(timeoutId.current);
        tick();
      }
    };

    document.addEventListener("visibilitychange", handleVisibilityChange);

    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, []);
};
