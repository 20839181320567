import { useContext, useEffect, useState } from "react";
import styled, { withTheme } from "styled-components";
import { connect, useSelector, useDispatch } from "react-redux";
import { GetFilter } from "utils/Color";
import { getTimeSince, getSignalStrengthIcon, getIsUnitActive } from "utils";
import { Icon } from "common";
import { LayoutTabs, setUnitsViewMode } from "features/grid-layout";
import { NavLink } from "react-router-dom";
import {
  setActiveMapMarker,
  setMapCenter,
  setOverlayShown as setOverlayShownAction,
} from "features/map/redux";
import { SignalIcon } from "./UnitsViewComponents";
import { SizeMe } from "react-sizeme";
import { Tooltip } from "react-tooltip";
import { useCurrentSNId } from "../hooks";
import { useCustomerId } from "features/customers/hooks/customersHooks";
import { useGetApplicationUnitsByFilterQuery } from "features/units/redux";
import { useSignalUISettingsForProductSN } from "features/applications/hooks";
import {
  S3_ARROW_DOWN,
  S3_ARROW_UP,
  S3_STATUS_DOT_ON,
  S3_STATUS_DOT_OFF,
  S3_STATUS_NORMAL,
  S3_STATUS_UNKNOWN,
  S3_MAP_PIN_UNIT_A,
  S3_LIST_LOCATION_ON,
  S3_LIST_LOCATION_OFF,
  S3_SIDEBAR_LEFT_UNITS_LIST,
  S3_CLOSE_X,
  S3_MENU_ELLIPSIS,
  ICON_SIZE_MD,
  ICON_SIZE_XL,
  ICON_SIZE_XS,
  ICON_SIZE_SM,
} from "utils/defines";
import {
  setCurrentProduct,
  setApplicationsSelectedProduct,
  setApplicationsSelectedSN,
  setApplicationsSelectedTab,
} from "features/applications/redux";
import { DeviceInfoModal } from "./DeviceInfoModal";
import { DataExporterModal } from "./DataExporterModal";
import { AuthContext } from "features/auth/contexts";
import { widgetTypesList } from "features/widgets/config/widgetDefines";

// TODO: pull from theme
const MAP_OUTER_PADDING = 30;
const MAP_OUTER_PADDING_SM = 120; // when expanded
const OVERLAYVERTICAL_WIDTH = 200;
const OVERLAYCLOSED_WIDTH = 25;

const Wrapper = styled.div`
  z-index: 1000;
  position: fixed;
  top: calc(${(props) => props.theme.headerHeight} + ${MAP_OUTER_PADDING}px);
  left: calc(
    ${(props) => props.theme.sidebarWidth} +
      ${(props) =>
        props.overlayShown
          ? OVERLAYVERTICAL_WIDTH + MAP_OUTER_PADDING_SM
          : OVERLAYCLOSED_WIDTH}px
  );
  height: ${(props) =>
    `calc(100vh - ${props.theme.headerHeight} - ${MAP_OUTER_PADDING}px)`};
  width: calc(
    100vw - ${(props) => props.theme.sidebarWidth} - ${MAP_OUTER_PADDING}px -
      ${(props) =>
        props.overlayShown
          ? OVERLAYVERTICAL_WIDTH + MAP_OUTER_PADDING_SM
          : OVERLAYCLOSED_WIDTH}px
  );
  opacity: 1;
  transform: scale(1);
  &[disabled] {
    visibility: hidden;
    opacity: 0;
    transform: scale(0.95);
    // width here is the same as non-disabled,
    // but ever so slightly smaller to force a width update on animation
    width: calc(
      99.5vw - ${(props) => props.theme.sidebarWidth} - ${MAP_OUTER_PADDING}px -
        ${(props) =>
          props.overlayShown
            ? OVERLAYVERTICAL_WIDTH + MAP_OUTER_PADDING_SM
            : OVERLAYCLOSED_WIDTH}px
    );
  }
  transition: all 0.3s;
  background-color: transparent;
`;

const Top = styled.div`
  align-items: center;
  background-color: rgba(255, 255, 255, 1);
  border-radius: 5px 5px 0 0;
  color: rgba(0, 0, 0, 0.7);
  display: flex;
  height: 80px; // @TODO: change from 80px to 60px and fix modal height
  opacity: 1;
  padding: 10px 15px;
  width: 100%;
  z-index: 1;
`;
const Serial = styled.span`
  align-items: center;
  align-self: center;
  display: flex;
  font-size: 24px;
  margin-left: 10px;
`;
const Icons = styled.div`
  align-items: center;
  display: flex;
  margin-left: 50px;
`;
const IconWrapper = styled.span`
  cursor: pointer;
  margin: 0px 8px;
`;
const UnitControls = styled.div`
  display: flex;
`;

const UnitMoreOptions = styled.div`
  position: relative;
`;

const UnitOptionsToggleWrapper = styled.div`
  background-color: ${(props) => (props.active ? "#f0f0f0" : "#fff")};
  border-radius: 5px;
  cursor: pointer;
  padding: 0 5px;
`;

const UnitOptionsDropdown = styled.div`
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  display: ${(props) => (props.show ? "block" : "none")};
  font-size: 14px;
  left: 0;
  margin-top: 5px;
  padding: 5px 0;
  position: absolute;
  top: 100%;
  width: 200px;
  z-index: 1001;
`;

const UnitOptionsItem = styled.div`
  background-color: #fff;
  padding: 5px 5px 5px 10px;
`;

const DeselectionCatcher = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 999;
`;

const OverlayButton = styled.button`
  align-items: center;
  border: none;
  display: flex;
  opacity: ${(props) => (props.active ? "1" : ".3")};
`;

const ShowList = styled(OverlayButton)`
  background-color: white;
  border-radius: 5px;
  margin-right: 20px;
  transition: all 1s;
`;

function truncateIfLong(str) {
  if (!str) return null;
  return str.length >= 20
    ? str.substr(0, 12) + "..." + str.substr(str.length - 5, str.length)
    : str;
}

const UnitModal = ({
  setCurrentSNId,
  setActiveMapMarker,
  overlayShown, // redux: map
  overlayVertical, // redux: map
  theme,
  mapCenter,
  setCurrentProduct,
}) => {
  const customerId = useCustomerId();
  const currentSNId = useCurrentSNId();
  const { isSuperAdmin } = useContext(AuthContext);
  const { data: productSNs } = useGetApplicationUnitsByFilterQuery({
    customer_id: customerId,
  });

  const [unitOptionsVisible, setUnitOptionsVisible] = useState(false);
  const [isDeviceInfoModalVisible, setIsDeviceInfoModalVisible] =
    useState(false);
  const [dataExporterModalVisible, setDataExporterModalVisible] =
    useState(false);

  const [staleDataCounter, setStaleDataCounter] = useState(0);
  useEffect(() => {
    const interval = setInterval(() => {
      setStaleDataCounter(staleDataCounter < 100 ? staleDataCounter + 1 : 0);
    }, 5000);

    return () => {
      clearInterval(interval);
    };
  }, [staleDataCounter]);

  // ported from old code; to be refactored
  const _productSN = productSNs?.length
    ? productSNs.find((p) => p.id === currentSNId)
    : null;
  const _productId = _productSN ? _productSN.product_id : null;
  const _product = useSelector((state) =>
    state.products.products.find((product) => product.id === _productId)
  );
  const dispatch = useDispatch();
  const setOverlayShown = (overlayShown) =>
    dispatch(setOverlayShownAction(overlayShown));
  const setSelectedApplication = (product) =>
    dispatch(setApplicationsSelectedProduct(product));
  const setSelectedTab = (tab) => dispatch(setApplicationsSelectedTab(tab));
  const setSelectedSN = (productSN) =>
    dispatch(setApplicationsSelectedSN(productSN));
  const {
    unitLatVal,
    unitLngVal,
    unitLastUpdated: lastUpdatedTime,
    unitSimplifiedRSSI,
  } = useSignalUISettingsForProductSN(_productSN);

  const signalStrengthIcon = getSignalStrengthIcon(unitSimplifiedRSSI);
  const signalReadout = unitSimplifiedRSSI
    ? `Signal Strength: ${unitSimplifiedRSSI}%`
    : "unknown";

  const product = _productSN;

  // DRY pattern
  const isIconNearCenter = () => {
    const DIFFERENCE_THRESHOLD = 0.002;
    const difference = (a, b) => Math.abs(a - b);
    const latIsClose =
      difference(mapCenter.lat, unitLatVal) < DIFFERENCE_THRESHOLD;
    const lngIsClose =
      difference(mapCenter.lng, unitLngVal) < DIFFERENCE_THRESHOLD;
    const result = latIsClose && lngIsClose;
    return result;
  };

  const switchToProductSN = (targetProductId) => {
    setActiveMapMarker(targetProductId);
    setCurrentSNId(targetProductId);
  };

  const previousUnit = () => {
    let index = productSNs?.length ? productSNs.indexOf(product) : null;
    if (index === null) return;
    index = index > 0 ? index - 1 : productSNs.length - 1;
    switchToProductSN(productSNs[index].id);
  };

  const nextUnit = () => {
    let index = productSNs?.length ? productSNs.indexOf(product) : null;
    if (index === null) return;
    index = index === productSNs.length - 1 ? 0 : index + 1;
    switchToProductSN(productSNs[index].id);
  };

  const navigateToUnitSettings = () => {
    setSelectedApplication(_product);
    setSelectedTab("units");
    setSelectedSN(_productSN);
  };

  const closeUnitOptions = () => setUnitOptionsVisible(false);
  const toggleUnitOptionsShown = () =>
    setUnitOptionsVisible(!unitOptionsVisible);

  const setViewMode = (viewMode) => dispatch(setUnitsViewMode(viewMode));
  return (
    <Wrapper
      className="product-wrapper"
      disabled={!product}
      overlayShown={overlayShown}
      overlayVertical={overlayVertical}
    >
      <div
        style={{
          boxShadow: "0px 4px 8px rgba(0,0,0,0.2)",
          position: "relative",
          backgroundColor: "#f0f0f0",
          borderRadius: "5px",
          // Should match LayoutTabsViewHeader's min-width
          minWidth: "600px",
        }}
      >
        <SizeMe monitorHeight>
          {({ size }) => (
            <div>
              {/* Wrapper div to fix position: relative / z-index issues: https://github.com/ctrlplusb/react-sizeme/issues/120#issue-247332198 */}
              <Top className="product-header">
                <Serial>
                  <div style={{ display: "flex", alignItems: "baseline" }}>
                    {!overlayShown && (
                      <ShowList
                        className="map-overlay-show-list-button"
                        onClick={() => setOverlayShown(true)}
                        active={true}
                        overlayShown={overlayShown}
                      >
                        <div style={{ marginTop: "5px", marginLeft: "-5px" }}>
                          <Icon
                            width={ICON_SIZE_MD}
                            height={ICON_SIZE_MD}
                            hostedImage={S3_SIDEBAR_LEFT_UNITS_LIST}
                          />{" "}
                        </div>
                      </ShowList>
                    )}
                    <Icon
                      style={{
                        filter: GetFilter(theme.themePrimary),
                        marginRight: "8px",
                        transform: "translate(0px, 8px)",
                      }}
                      width={ICON_SIZE_XL}
                      height={ICON_SIZE_XL}
                      hostedImage={S3_MAP_PIN_UNIT_A}
                    />
                    <div style={{ marginRight: "5px", fontWeight: "400" }}>
                      {truncateIfLong(product?.product_sn_name)}{" "}
                    </div>

                    <span
                      style={{
                        position: "relative",
                        left: "25px",
                        top: "-20px",
                        marginRight: "30px",
                        marginLeft: "10px",
                      }}
                    >
                      <span
                        data-tooltip-id="productheader-tooltip"
                        data-tooltip-content={`Last Updated: ${getTimeSince(
                          lastUpdatedTime
                        )}`}
                        style={{
                          position: "absolute",
                          left: "-25px",
                          top: "-6px",
                        }}
                      >
                        <Icon
                          hostedImage={
                            getIsUnitActive(lastUpdatedTime)
                              ? S3_STATUS_DOT_ON
                              : S3_STATUS_DOT_OFF
                          }
                          height={ICON_SIZE_XS}
                          width={ICON_SIZE_XS}
                        />
                      </span>
                    </span>
                    <span
                      data-tooltip-id="productheader-tooltip"
                      data-tooltip-content={signalReadout || "Unknown"}
                    >
                      <div style={{ marginLeft: "10px" }}>
                        <SignalIcon hostedImage={signalStrengthIcon} />
                      </div>
                    </span>
                    <Icon
                      style={{
                        marginLeft: "20px",
                        transform: "translate(0, 2px)",
                      }}
                      hostedImage={
                        getIsUnitActive(lastUpdatedTime)
                          ? S3_STATUS_NORMAL
                          : S3_STATUS_UNKNOWN
                      }
                      width={ICON_SIZE_XS}
                      height={ICON_SIZE_XS}
                    />
                    <span
                      style={{
                        textTransform: "uppercase",
                        fontWeight: "bold",
                        fontSize: "10px",
                        paddingLeft: "4px",
                        display: size.width > 1125 ? "inline-block" : "none",
                        transform: "translate(0, -2px)",
                      }}
                    >
                      {getIsUnitActive(lastUpdatedTime)
                        ? `Normal Operation`
                        : `Unknown`}
                    </span>
                    <span
                      style={{
                        marginLeft: "20px",
                        fontSize: "14px",
                        display: size.width > 1025 ? "inline-block" : "none",
                      }}
                    >
                      TCU SN#{product?.serial_number}
                    </span>
                    <div
                      style={{
                        fontSize: "14px",
                        marginRight: "15px",
                        marginLeft: "15px",
                      }}
                    >
                      {/* <Icon
                        style={{
                          width: "15px",
                          height: "auto",
                          marginRight: "4px",
                          opacity: "0.5",
                          transform: "translate(0, -2px)",
                        }}
                        hostedImage={S3_ARROWS_ROTATE}
                      /> */}
                      {/* {size.width > 900 ? "Updated: " : ""}
                      <span
                        style={{
                          display: size.width > 800 ? "inline-block" : "none",
                          fontWeight: 700,
                        }}
                      >
                        {getTimeSince(lastUpdatedTime)}
                      </span> */}
                    </div>
                    {isSuperAdmin && (
                      <UnitMoreOptions>
                        <UnitOptionsToggleWrapper
                          active={unitOptionsVisible}
                          onClick={toggleUnitOptionsShown}
                        >
                          <Icon
                            width={ICON_SIZE_SM}
                            height={ICON_SIZE_SM}
                            hostedImage={S3_MENU_ELLIPSIS}
                          />
                        </UnitOptionsToggleWrapper>
                        <UnitOptionsDropdown show={unitOptionsVisible}>
                          <UnitOptionsItem>
                            <NavLink
                              to="/applications"
                              style={{
                                color: "inherit",
                                textDecoration: "none",
                              }}
                              onClick={navigateToUnitSettings}
                            >
                              Edit Unit Settings
                            </NavLink>
                          </UnitOptionsItem>
                          <UnitOptionsItem>
                            <div
                              style={{ cursor: "pointer" }}
                              onClick={() => {
                                setIsDeviceInfoModalVisible(true);
                                setUnitOptionsVisible(false);
                              }}
                            >
                              View Device Info
                            </div>
                          </UnitOptionsItem>
                          <UnitOptionsItem>
                            <div
                              style={{ cursor: "pointer" }}
                              onClick={() => {
                                setDataExporterModalVisible(true);
                                setUnitOptionsVisible(false);
                              }}
                            >
                              Export Data
                            </div>
                          </UnitOptionsItem>
                        </UnitOptionsDropdown>
                        {unitOptionsVisible && (
                          <DeselectionCatcher onClick={closeUnitOptions} />
                        )}
                      </UnitMoreOptions>
                    )}
                  </div>
                </Serial>
                <div
                  style={{
                    marginLeft: "auto",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <span
                    onClick={() => previousUnit()}
                    style={{ cursor: "pointer" }}
                    data-tooltip-id="productheader-tooltip"
                    data-tooltip-content="Previous Unit"
                  >
                    <Icon
                      hostedImage={S3_ARROW_UP}
                      width={ICON_SIZE_MD}
                      height={ICON_SIZE_MD}
                    />
                    <span style={{ fontSize: "15px", marginLeft: "4px" }}>
                      {size.width > 1445
                        ? "Prev Unit"
                        : size.width > 720
                        ? "Prev"
                        : ""}
                    </span>
                  </span>
                  <span
                    onClick={() => nextUnit()}
                    style={{ cursor: "pointer" }}
                    data-tooltip-id="productheader-tooltip"
                    data-tooltip-content="Next Unit"
                  >
                    <Icon
                      hostedImage={S3_ARROW_DOWN}
                      width={ICON_SIZE_MD}
                      height={ICON_SIZE_MD}
                      style={{ marginLeft: "20px" }}
                    />
                    <span style={{ fontSize: "15px", marginLeft: "4px" }}>
                      {size.width > 1445
                        ? "Next Unit"
                        : size.width > 720
                        ? "Next"
                        : ""}
                    </span>
                  </span>
                  <span
                    onClick={() => {
                      setViewMode("map");
                      if (unitLatVal && unitLngVal) {
                        setActiveMapMarker(product?.id, {
                          lat: unitLatVal,
                          lng: unitLngVal,
                        });
                        setCurrentProduct(null);
                      }
                    }}
                    style={
                      unitLatVal && unitLngVal ? { cursor: "pointer" } : {}
                    }
                  >
                    <Icon
                      hostedImage={
                        isIconNearCenter()
                          ? S3_LIST_LOCATION_ON
                          : S3_LIST_LOCATION_OFF
                      }
                      width={ICON_SIZE_MD}
                      height={ICON_SIZE_MD}
                      style={{
                        opacity: unitLatVal && unitLngVal ? "1" : "0.2",
                        marginLeft: "30px",
                        marginRight: "-20px",
                      }}
                    />
                  </span>
                </div>
                <UnitControls />
                <Icons>
                  <IconWrapper
                    onClick={() => {
                      setActiveMapMarker(product?.id);
                      setCurrentSNId(null);
                    }}
                    style={{ padding: "15px", margin: "-8px" }}
                  >
                    <Icon
                      width={ICON_SIZE_XL}
                      height={ICON_SIZE_XL}
                      hostedImage={S3_CLOSE_X}
                      style={{ marginTop: "2px" }}
                    />
                  </IconWrapper>
                </Icons>
                <Tooltip place="top" id="productheader-tooltip" />
              </Top>
            </div>
          )}
        </SizeMe>
        <LayoutTabs
          section="products"
          productID={product?.product_id}
          widgetType={widgetTypesList.product}
          overlayShown={overlayShown}
          overlayVertical={overlayVertical}
        />
        <DeviceInfoModal
          show={isDeviceInfoModalVisible}
          onCancel={() => setIsDeviceInfoModalVisible(false)}
          productSN={_productSN}
        />
        <DataExporterModal
          open={dataExporterModalVisible}
          onCancel={() => setDataExporterModalVisible(false)}
          productSN={_productSN}
        />
      </div>
    </Wrapper>
  );
};

const mapStateToProps = (state) => ({
  overlayShown: state.map.overlayShown,
  overlayVertical: state.map.overlayVertical,
  mapCenter: state.map.mapCenter,
  dataHistory: state.productSNData.dataHistory,
  productSettings: state.products.productSettings,
});

const mapDispatchToProps = (dispatch) => ({
  setCurrentSNId: (productId) => dispatch(setCurrentProduct(productId)),
  setCurrentProduct: (product) => dispatch(setCurrentProduct(product)), // this is the idiomatic way
  setActiveMapMarker: (marker, coordinates) =>
    dispatch(setActiveMapMarker({ marker, coordinates })),
  setMapCenter: (coordinates) => dispatch(setMapCenter(coordinates)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTheme(UnitModal));
