import { useState, useEffect } from "react";
import styled from "styled-components";
import { withSize } from "react-sizeme";
import { WidgetTitle, BigNumber } from "../TileComponents";
import { useGetCustomerApplicationDataUsageQuery } from "features/applications/redux";
import { Spinner } from "common/Spinner";
import { useCustomerId } from "features/customers/hooks";
import { BYTES_AS_GB, BYTES_AS_MB } from "utils/defines";
import { getBytesAsGB, getBytesAsKB, getBytesAsMB } from "utils";

const DataUsageContainer = styled.div`
  height: 100%;
  padding: 0 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const DataUsageRow = styled.div`
  max-width: 400px;
  margin: 0 auto;
  display: flex;
  &:first-of-type {
    margin-bottom: 20px;
  }
  &.compressed {
    display: block;
  }
`;

const BigNumberWrapper = styled.div`
  text-align: right;
  margin: 0 10px;
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  &.compressed {
    text-align: center;
    align-items: center;
  }
`;

const ModifiedBigNumber = styled(BigNumber)`
  font-size: 3em;
  & span.units {
    font-size: 15px;
    margin-left: 5px;
  }
  &.compressed {
    font-size: 2em;
  }
`;

const NumberLabel = styled.div`
  text-align: left;
  padding-left: 5px;
  width: 200px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  &.compressed {
    text-align: center;
    width: 100%;
  }
`;

const ModifiedWidgetTitle = styled(WidgetTitle)`
  text-align: left;
  font-size: 18px;
  font-weight: 300;
  &.compressed {
    text-align: center;
  }
`;

const displayTotalDataUsage = (data) => {
  let dataUsageString = "";
  let dataUsageUnits = "";

  if (data < BYTES_AS_MB) {
    dataUsageString = `${getBytesAsKB(data).toFixed(2)}`;
    dataUsageUnits = "KB";
  } else if (data >= BYTES_AS_MB && data < BYTES_AS_GB) {
    dataUsageString = `${getBytesAsMB(data).toFixed(2)}`;
    dataUsageUnits = "MB";
  } else if (data >= BYTES_AS_GB) {
    dataUsageString = `${getBytesAsGB(data).toFixed(2)}`;
    dataUsageUnits = "GB";
  }

  return (
    <>
      {dataUsageString}
      <span className="units">{dataUsageUnits}</span>
    </>
  );
};

const DashboardDataWidget = ({ size }) => {
  const customerID = useCustomerId();
  const [isWidgetLoaded, setIsWidgetLoaded] = useState(false);

  const [dataUsage, setDataUsage] = useState(0);

  const SIZE_BREAKPOINT = 320;
  const sizeClassName = size.width < SIZE_BREAKPOINT ? "compressed" : "";

  // load data
  const {
    data: qCustomerApplicationDataUsage,
    isLoading: qCustomerApplicationDataUsageLoading,
  } = useGetCustomerApplicationDataUsageQuery(
    {
      customer_id: customerID,
      time_range: "total_last_month",
    },
    { skip: customerID == null }
  );

  // fetch data on render
  useEffect(() => {
    setIsWidgetLoaded(!qCustomerApplicationDataUsageLoading);
    if (isWidgetLoaded) {
      const totalDataUsage = qCustomerApplicationDataUsage?.reduce(
        (total, item) => {
          return total + (item.hologramUsage?.bytes ?? 0);
        },
        0
      );
      setDataUsage(totalDataUsage ?? 0);
    }
  }, [qCustomerApplicationDataUsageLoading, isWidgetLoaded]);

  if (!isWidgetLoaded) return <Spinner />;

  return (
    <DataUsageContainer className={sizeClassName}>
      <div>
        <DataUsageRow className={sizeClassName}>
          <BigNumberWrapper className={sizeClassName}>
            <ModifiedBigNumber className={sizeClassName}>
              {displayTotalDataUsage(dataUsage)}
            </ModifiedBigNumber>
          </BigNumberWrapper>
          <NumberLabel className={sizeClassName}>
            <ModifiedWidgetTitle className={sizeClassName}>
              Total Data Used Past 30 Days
            </ModifiedWidgetTitle>
          </NumberLabel>
        </DataUsageRow>
      </div>
    </DataUsageContainer>
  );
};

export const DashboardDataUsage = withSize()(DashboardDataWidget);
