import { SizeMe } from "react-sizeme";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { formatNumber, getTimeSince } from "utils";
import {
  BigNumber,
  WidgetBody,
  WidgetTitle,
  WidgetWrapper,
} from "../TileComponents";
import { useCurrentProductSN } from "features/units/hooks";
import { useSignalUISettingsForProductSN } from "features/applications/hooks";
import { useEffect, useState } from "react";

// DRY
const DOUBLE_EN_DASH = `${String.fromCharCode(8211)}${String.fromCharCode(
  8211
)}`;

// DRY
const TextBoxMeta = styled.span`
  color: #aaa;
  font-size: ${({ metaValueFontSize }) => metaValueFontSize + "px"};
  padding: 0 8px;
`;

// format for display
/* todo: make this reusable. might be a good IS method */
const getDisplayContentFromDataHistory = (
  signalDatum,
  dataHistoryForPsn,
  signalSettings
) => {
  const signalId = signalDatum.signal.id;
  const { unitSimplifiedRSSI, rssi_signal_id } = signalSettings;

  // if no history, exit early
  if (!Object.keys(dataHistoryForPsn).length) return null;

  // raw value, unit symbol from signal
  let dataForSignal = dataHistoryForPsn[signalId] || [];

  // if no history, exit early
  if (!dataForSignal || !dataForSignal.length) return null;

  // get the latest dataHistory point
  // let { data_value } = signalDatum.signal_data;
  let { data_value } = dataForSignal[dataForSignal.length - 1];
  let data_symbol = signalDatum.getUnitSymbol();
  let displaySymbol = data_symbol; // default, just pass the symbol thru

  let displayValue, suffix;
  displayValue = formatNumber(data_value, signalDatum.getPrecision());
  if (signalId === rssi_signal_id) {
    displayValue = unitSimplifiedRSSI;
    suffix = "%";
  }

  // if nothing to display, exit early
  if (!displayValue) return null;

  // add spaces if new pieces
  let result = (
    <>
      {displayValue}{" "}
      <span className="signal-unit">
        {displaySymbol}
        {suffix ? ` ${suffix}` : ""}
      </span>
    </>
  );
  return result;
};

// DRY
const getDisplayTimestampFromDataHistory = (signalDatum, dataHistoryForPsn) => {
  const signalId = signalDatum.signal.id;

  // if no history, exit early
  if (!Object.keys(dataHistoryForPsn).length) return null;

  // raw value, unit symbol from signal
  let dataForSignal = dataHistoryForPsn[signalId] || [];

  // if no history, exit early
  if (!dataForSignal || !dataForSignal.length) return null;

  // get the latest dataHistory point
  // let { data_value } = signalDatum.signal_data;
  let { timestamp_recorded } = dataForSignal[dataForSignal.length - 1];

  // if nothing to display, exit early
  if (!timestamp_recorded) return null;

  // add spaces if new pieces
  return timestamp_recorded;
};

export const TextDisplay = ({
  currentProduct: currentProductSNId,
  instance_signals,
  getSettingsField,
  isMapModalLayout, // MapModal > Layout > Grid > Widget
}) => {
  // get dataHistory
  const dataHistoryForPsn = useSelector(
    (state) => state.productSNData.dataHistory[currentProductSNId] || {}
  );
  const [currentProductSN, _] = useCurrentProductSN();
  const signalSettings = useSignalUISettingsForProductSN(currentProductSN);
  const [staleDataCounter, setStaleDataCounter] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setStaleDataCounter(staleDataCounter < 100 ? staleDataCounter + 1 : 0);
    }, 5000);

    return () => {
      clearInterval(interval);
    };
  }, [staleDataCounter]);

  // if no signal, don't go forward
  if (!instance_signals.length) return <div>No signal loaded</div>;

  // assumes exactly one signal allowed
  // extract the InstanceSignal from the first (only) one
  const { IS } = instance_signals[0];

  // widget header
  const widgetTitle = getSettingsField("widgetTitle");
  const signalName = IS.getSignalName();
  const displayTitle = widgetTitle || signalName;

  // widget body
  const timestampRaw = getDisplayTimestampFromDataHistory(
    IS,
    dataHistoryForPsn
  );
  const displayTimestamp = timestampRaw ? getTimeSince(timestampRaw) : null;
  const displayContent = getDisplayContentFromDataHistory(
    IS,
    dataHistoryForPsn,
    signalSettings
  );

  return (
    <SizeMe monitorHeight>
      {({ size }) => (
        <WidgetWrapper>
          <WidgetBody>
            <TextBox
              isMapModalLayout={isMapModalLayout}
              metaValue={displayTimestamp}
              size={size}
              title={displayTitle}
              value={displayContent}
            />
          </WidgetBody>
        </WidgetWrapper>
      )}
    </SizeMe>
  );
};

/** the purely functional widget */
// also being used in SignalAggregator
export const TextBox = ({
  isMapModalLayout,
  metaValue,
  size,
  title,
  value,
}) => {
  if (!size) return null;
  const isHorizontalMode = size.width / size.height > 2.5;
  const scaleFactor = isMapModalLayout ? 1.2 : 1;
  const nominalFontValue = 30;
  const valueFontSize = nominalFontValue / scaleFactor;
  const titleFontSize = valueFontSize / 1.6;
  const metaValueFontSize = titleFontSize / 1.6;
  return (
    <div
      style={{
        display: isHorizontalMode ? "flex" : "block",
        justifyContent: "center",
        padding: "0 20px",
      }}
    >
      <div
        style={
          isHorizontalMode
            ? {
                alignItems: "flex-end",
                display: "flex",
                flex: "0 0",
                flexDirection: "column",
                justifyContent: "center",
                paddingRight: "5px",
              }
            : {}
        }
      >
        {value !== null ? (
          <BigNumber
            fontSize={valueFontSize}
            style={{ fontSize: valueFontSize }}
          >
            {value}
          </BigNumber>
        ) : (
          <span style={{ color: "#888" }} title="No Data">
            {DOUBLE_EN_DASH}
          </span>
        )}
      </div>
      <div
        style={
          isHorizontalMode
            ? {
                alignItems: "flex-start",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                paddingLeft: "5px",
                textAlign: "left",
              }
            : { textWrap: "pretty", width: "100%" }
        }
      >
        {title && (
          <WidgetTitle
            style={{
              fontSize: titleFontSize,
              textAlign: isHorizontalMode ? "left" : "center",
            }}
            thinFont
          >
            {title}
          </WidgetTitle>
        )}
        {metaValue && !isMapModalLayout && (
          <TextBoxMeta metaValueFontSize={metaValueFontSize}>
            {metaValue}
          </TextBoxMeta>
        )}
      </div>
    </div>
  );
};
