import { Spinner } from "common/Spinner";
import { useGetCustomerApplicationSignalDistributionQuery } from "features/applications/redux";
import { useCustomerId } from "features/customers/hooks";
import { useEffect, useState } from "react";
import Plot from "react-plotly.js";
import styled, { useTheme } from "styled-components";
import { WidgetBody } from "../TileComponents";
import { withSize } from "react-sizeme";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  container-type: inline-size;
`;
const DistributionBarGraphWidget = ({
  instance_signals,
  getSettingsField,
  size,
}) => {
  const customerID = useCustomerId();
  const theme = useTheme();
  const [isWidgetLoaded, setIsWidgetLoaded] = useState(false);
  const [signal, setSignal] = useState({});
  const [plotTitle, setPlotTitle] = useState("");
  const [plotFontFamily, setPlotFontFamily] = useState("");
  const [plotXTitle, setPlotXTitle] = useState("");
  const [plotXRange, setPlotXRange] = useState([]);
  const [plotYRange, setPlotYRange] = useState([]);
  const [plotYTitle, setPlotYTitle] = useState("");
  const [plotData, setPlotData] = useState([]);

  // widget settings
  const widgetNumOfBins = getSettingsField("wNumOfBins");
  const widgetMinBinValue = getSettingsField("wBinMinValue");
  const widgetMaxBinValue = getSettingsField("wBinMaxValue");
  const widgetTitle = getSettingsField("wTitle", "");
  const widgetFontFamily = getSettingsField("wFontFamily");
  const widgetXTitle = getSettingsField("wXTitle", "");
  const widgetYTitle = getSettingsField("wYTitle", "");
  const widgetOrientation = getSettingsField("wOrientation");
  const widgetMarkerColor = getSettingsField("wMarkerColor", "");

  // load data
  const {
    data: qCustomerApplicationSignalDistribution,
    isLoading: isQCustomerApplicationSignalDistributionLoading,
  } = useGetCustomerApplicationSignalDistributionQuery(
    {
      customer_id: customerID,
      signal_ids: [signal?.id],
    },
    { skip: customerID == null || Object.keys(signal).length === 0 }
  );

  useEffect(() => {
    setIsWidgetLoaded(
      !isQCustomerApplicationSignalDistributionLoading &&
        qCustomerApplicationSignalDistribution !== null &&
        instance_signals.length > 0
    );

    if (instance_signals.length > 0) {
      const { signal } = instance_signals[0];
      setSignal(signal);
    }
  }, [isQCustomerApplicationSignalDistributionLoading, instance_signals]);

  useEffect(() => {
    if (isWidgetLoaded) {
      const binMax =
        widgetMaxBinValue !== undefined ? widgetMaxBinValue : signal?.value_max;
      const binMin =
        widgetMinBinValue !== undefined ? widgetMinBinValue : signal?.value_min;
      const binPer = (binMax - binMin) / widgetNumOfBins;
      const binUnits = signal?.signal_unit?.symbol;
      let bins = {};
      let binRanges = [];
      // initializing bins, dynamically calculate bin ranges
      for (let i = 0; i < widgetNumOfBins; i++) {
        bins[i] = 0;
        const binRangeStart = parseFloat(binMin) + binPer * i;
        const binRangeEnd =
          i === widgetNumOfBins - 1
            ? parseFloat(binMax)
            : binRangeStart + binPer - 1;

        // setting bin ranges
        binRanges.push(
          `${binRangeStart.toFixed(0)}-${binRangeEnd.toFixed(0)} ${binUnits}`
        );
      }

      // iterate and assign bin totals
      qCustomerApplicationSignalDistribution?.forEach((item) => {
        const signalVal = item.data_value;
        const signalBin = Math.floor((signalVal - binMin) / binPer);
        if (signalBin < widgetNumOfBins) {
          if (signalVal === binMax) {
            bins[signalBin - 1] += 1;
          } else {
            bins[signalBin] += 1;
          }
        }
      });

      let _plotData = {
        x: [],
        y: [],
        type: "bar",
        orientation: widgetOrientation,
        marker: {
          color:
            widgetMarkerColor === ""
              ? theme.themePrimary
              : `rgb(${widgetMarkerColor.r},${widgetMarkerColor.g},${widgetMarkerColor.b})`,
        },
      };

      if (widgetOrientation === "v") {
        // vertical
        _plotData.x = binRanges;
        _plotData.y = Object.values(bins);

        let yMaxValue = 1;
        _plotData.y.forEach((item) => {
          if (item > yMaxValue) yMaxValue = item;
        });

        setPlotYRange([0, yMaxValue]);
        setPlotXTitle(widgetXTitle !== "" ? widgetXTitle : "Percentage");
        setPlotYTitle(widgetYTitle !== "" ? widgetYTitle : "Number of Units");
      } else {
        // horizontal
        _plotData.y = binRanges;
        _plotData.x = Object.values(bins);

        let xMaxValue = 1;
        _plotData.x.forEach((item) => {
          if (item > xMaxValue) xMaxValue = item;
        });

        setPlotXRange([0, xMaxValue]);

        setPlotXTitle(widgetXTitle !== "" ? widgetXTitle : "Number of Units");
        setPlotYTitle(widgetYTitle !== "" ? widgetYTitle : "Percentage");
      }

      // update font family
      if (widgetFontFamily === "2") {
        setPlotFontFamily("Rubik");
      } else if (widgetFontFamily === "3") {
        setPlotFontFamily("PilatWide");
      }

      setPlotTitle(
        widgetTitle !== "" ? widgetTitle : `${signal?.name} Distribution`
      );
      setPlotData([_plotData]);
    }
  }, [isWidgetLoaded]);

  // show spinner if widget not loaded
  if (!isWidgetLoaded || !instance_signals.length) return <Spinner />;

  return (
    <>
      <Wrapper className="distribution-bar-graph-wrapper">
        <WidgetBody>
          <Plot
            style={{
              position: "relative",
              display: "inline-block",
              width: "100%",
              height: "100%",
            }}
            data={plotData}
            layout={{
              font: {
                family: `${plotFontFamily}`,
              },
              title: {
                text: `${plotTitle}`,
              },
              xaxis: {
                title: {
                  text: `${plotXTitle}`,
                  standoff: 15,
                },
                range: plotXRange,
              },
              yaxis: {
                title: {
                  text: `${plotYTitle}`,
                  standoff: 15,
                },
                range: plotYRange,
                automargin: true,
              },
              width: size.width,
              height: size.height,
              responsive: true,
              dragmode: false,
            }}
            config={{
              doubleClick: false,
            }}
          />
        </WidgetBody>
      </Wrapper>
    </>
  );
};

export const DistributionBarGraph = withSize({ monitorHeight: true })(
  DistributionBarGraphWidget
);
