import styled from "styled-components";
import { Flex, Tooltip, Typography } from "antd";
import { Icon } from "common";
import { useDeviceStatuses } from "features/units/hooks";
import { S3_ARROWS_ROTATE } from "utils/defines";

export const AddUnitWrapper = styled.div`
  padding-right: 25px;
`;

export const ListViewWrapper = styled.div`
  background-color: #f0f0f0;
  height: 100%;
`;

export const NewUnitIcon = styled(Icon)`
  filter: invert(100%);
  height: auto;
  margin-right: 5px;
  width: 15px;
`;

export const SignalIcon = styled(Icon)`
  height: 16;
  margin-right: 10px;
  width: 16px;
`;

export const SummaryBlock = styled.div`
  align-items: center;
  display: flex;
  padding: 0 30px;
`;

export const SummaryNumber = styled.span`
  font-family: Rubik;
  font-size: 30px;
  font-weight: bold;
`;

export const SummaryTitle = styled.div`
  color: #333;
  font-weight: 90%;
  font-weight: bold;
  margin-right: 30px;
  text-wrap: nowrap;
`;

export const SummaryWrapper = styled.div`
  align-items: center;
  display: flex;
  flex: 1;
  padding-left: 70px;
  padding: 20px;
`;

export const UnitActionIcon = styled(Icon)`
  height: auto;
  margin-left: 10px;
  opacity: ${(props) => (props.isClickable ? "1" : "0.2")};
  transform: translate(6px, 0);
  width: 18px;
`;

const SummaryToolTip = ({ children, content }) => (
  <Tooltip style={{ display: "flex", flexDirection: "row" }} title={content}>
    <div style={{ display: "flex" }}>{children}</div>
  </Tooltip>
);

export const SummaryRow = () => {
  const deviceStatuses = useDeviceStatuses();

  const iconStyling = {
    height: "auto",
    margin: "0 10px 0 20px",
    width: "15px",
  };
  const summaryContainerStyle = {
    padding: "0 20px",
    alignItems: "center",
    wordBreak: "keep-all",
    wrap: "none",
    whiteSpace: "nowrap",
  };
  const summaryNumberStyle = {
    fontFamily: "Rubik",
    fontSize: "24px",
    fontWeight: "bold",
  };
  const summaryTextStyle = {
    alignContent: "center",
    fontSize: "20px",
  };
  return (
    <Flex
      style={{
        alignItems: "center",
        padding: "20px 0 20px 5px",
        justifyContent: "center",
        width: "100%",
        flexWrap: "wrap",
      }}
    >
      <Typography.Text
        strong
        style={{
          marginBottom: 0,
          fontSize: "16px",
          marginRight: "10px",
          textWrap: "nowrap",
        }}
      >
        SUMMARY:
      </Typography.Text>
      <Flex style={summaryContainerStyle}>
        <SummaryToolTip content={"Live Within The Last 5 Minutes"}>
          <Typography.Text style={summaryNumberStyle}>
            {deviceStatuses["live"]}
          </Typography.Text>
          <Icon style={iconStyling} hostedImage={S3_ARROWS_ROTATE} />
          <Typography.Text style={summaryTextStyle}>Live</Typography.Text>
        </SummaryToolTip>
      </Flex>
      <Flex style={summaryContainerStyle}>
        <SummaryToolTip content={"Live Within The Last Day"}>
          <Typography.Text style={summaryNumberStyle}>
            {deviceStatuses["lastDay"]}
          </Typography.Text>{" "}
          <Icon style={iconStyling} hostedImage={S3_ARROWS_ROTATE} />{" "}
          <Typography.Text style={summaryTextStyle}>Last Day</Typography.Text>
        </SummaryToolTip>
      </Flex>
      <Flex style={summaryContainerStyle}>
        <SummaryToolTip content={"Live Within The Last Week"}>
          <Typography.Text style={summaryNumberStyle}>
            {deviceStatuses["lastWeek"]}
          </Typography.Text>{" "}
          <Icon style={iconStyling} hostedImage={S3_ARROWS_ROTATE} />{" "}
          <Typography.Text style={summaryTextStyle}>Last Week</Typography.Text>
        </SummaryToolTip>
      </Flex>
      <Flex style={summaryContainerStyle}>
        <SummaryToolTip content={"Live Within The Last Month"}>
          <Typography.Text style={summaryNumberStyle}>
            {deviceStatuses["lastMonth"]}
          </Typography.Text>{" "}
          <Icon style={iconStyling} hostedImage={S3_ARROWS_ROTATE} />{" "}
          <Typography.Text style={summaryTextStyle}>Last Month</Typography.Text>
        </SummaryToolTip>
      </Flex>
      <Flex style={summaryContainerStyle}>
        <SummaryToolTip content={"Live Over A Month Ago"}>
          <Typography.Text style={summaryNumberStyle}>
            {deviceStatuses["overAMonth"]}
          </Typography.Text>{" "}
          <Icon style={iconStyling} hostedImage={S3_ARROWS_ROTATE} />{" "}
          <Typography.Text style={summaryTextStyle}>
            Over A Month
          </Typography.Text>
        </SummaryToolTip>
      </Flex>
      <Flex style={summaryContainerStyle}>
        <SummaryToolTip content={"No data available"}>
          <Typography.Text style={summaryNumberStyle}>
            {deviceStatuses["unknown"]}
          </Typography.Text>{" "}
          <Icon style={iconStyling} hostedImage={S3_ARROWS_ROTATE} />{" "}
          <Typography.Text style={summaryTextStyle}>Unknown</Typography.Text>
        </SummaryToolTip>
      </Flex>
    </Flex>
  );
};
