import SettingsField from "features/widgets/widgetlibrary/SettingsField";
import { RadioButton } from "common/elements";

export const SimpleChartSettings = ({ changeSettings, getField }) => {
  return (
    <div>
      <SettingsField label="Aggegation">
        <select
          value={getField("aggregationMode", "average")}
          onChange={(e) => changeSettings("aggregationMode", e.target.value)}
        >
          <option value="total">Total</option>
          <option value="average">Average</option>
        </select>
      </SettingsField>
      <SettingsField label="# of Months">
        <input
          value={getField("numMonths", "")}
          onChange={(e) => changeSettings("numMonths", e.target.value)}
        />
      </SettingsField>
      <SettingsField label="Chart Type">
        <select
          value={getField("chartType", "scatter")}
          onChange={(e) => changeSettings("chartType", e.target.value)}
        >
          <option value="scatter">Line</option>
          <option value="bar">Bar</option>
        </select>
      </SettingsField>
      <SettingsField label="Title">
        <input
          value={getField("widgetTitle", "")}
          onChange={(e) => changeSettings("widgetTitle", e.target.value)}
        />
      </SettingsField>
      <SettingsField label="Background">
        <RadioButton
          name="hide_background"
          id="visible"
          value={0}
          checked={!getField("background_hide", 0)}
          onChange={(e) =>
            changeSettings("background_hide", e.target.checked ? 0 : 1)
          }
          text="Visible"
        />
        <RadioButton
          name="hide_background"
          id="hidden"
          value={1}
          checked={getField("background_hide", 0)}
          onChange={(e) =>
            changeSettings("background_hide", e.target.checked ? 1 : 0)
          }
          text="Hidden"
        />
      </SettingsField>
    </div>
  );
};
