import ColorPicker from "features/widgets/widgetlibrary/AnyText/ColorPicker";
import SettingsField from "features/widgets/widgetlibrary/SettingsField";
import { RadioButton } from "common/elements";

export const AnyTextSettings = ({ changeSettings, getField }) => {
  return (
    <div>
      <SettingsField label="Text">
        <input
          value={getField("text", "")}
          onChange={(e) => changeSettings("text", e.target.value)}
        />
      </SettingsField>
      <SettingsField label="Text Color">
        <ColorPicker
          color={getField("color", { h: 0, s: 0, l: 0, a: 0 })}
          onChange={(c) => {
            changeSettings("color", c);
          }}
        />
      </SettingsField>
      <SettingsField label="Background">
        <RadioButton
          name="hide_background"
          id="visible"
          value={0}
          checked={!getField("background_hide", 0)}
          onChange={(e) =>
            changeSettings("background_hide", e.target.checked ? 0 : 1)
          }
          text="Visible"
        />
        <RadioButton
          name="hide_background"
          id="hidden"
          value={1}
          checked={getField("background_hide", 0)}
          onChange={(e) =>
            changeSettings("background_hide", e.target.checked ? 1 : 0)
          }
          text="Hidden"
        />
      </SettingsField>
    </div>
  );
};
