import styled from "styled-components";
import LayoutTabs from "../pages/LayoutTabs";
import { MainLayout } from "common/layout";
import { widgetTypesList } from "features/widgets/config/widgetDefines";

const Wrapper = styled.div`
  height: 100%;
`;

export const Dashboard = () => {
  return (
    <MainLayout>
      <Wrapper className="dashboard-wrapper">
        <LayoutTabs
          section={"dashboard"}
          widgetType={widgetTypesList.fleetwide}
        />
      </Wrapper>
    </MainLayout>
  );
};
