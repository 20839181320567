import * as API from "axios/axios";

export const tcuService = {
  SendCommand: (data) => API.post(`/api/iot/command`, data),
  SendCANCommand: (data) => API.post(`/api/iot/command/can`, data),
};

export class TcuUploadHandler {
  constructor({ token }) {
    this.token = token;
  }
  async requestDataForDateRange([start, end]) {
    return API.post("/api/iot/upload", {
      token: this.token,
      start,
      end,
    });
  }
  async sendCancel() {
    return API.post("/api/iot/upload/cancel", { token: this.token });
  }
}
