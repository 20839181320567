import { Table, Row, Col, Input } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";
import {
  SummaryTitle,
  SummaryBlock,
  SummaryNumber,
  SummaryWrapper,
} from "features/units/components";
import { ErrorExIcon, ConnectedIcon } from "./TCUPageComponents";
import { GetFilter } from "utils/Color";
import { Icon } from "common";
import { useTheme } from "styled-components";
import * as Defines from "utils/defines";
import Title from "antd/es/typography/Title";
import { Spinner } from "common/Spinner";

export const TCUPageOverviewTable = ({ qTCUs, isPageLoaded }) => {
  const [tcus, setTCUs] = useState([]);
  const [filteredTCUs, setFilteredTCUs] = useState([]);

  const theme = useTheme();
  const iconColor = theme.themePrimary;

  const [numOfActiveTCUs, setNumOfActiveTCUs] = useState(0);
  const [numOfUnlinkedTCUs, setNumofUnlinkedTCUs] = useState(0);

  useEffect(() => {
    if (isPageLoaded) {
      const updatedTCUs = qTCUs?.map((obj, index) => {
        return {
          ...obj,
          key: obj.id,
          index: index + 1,
          application_unit_name: obj.product_sn?.product_sn_name,
        };
      });

      let _numOfUnlinkedTCUs = qTCUs.filter(
        (tcu) => tcu.product_sn_id == null
      ).length;
      setNumOfActiveTCUs(qTCUs.length);
      setNumofUnlinkedTCUs(_numOfUnlinkedTCUs);

      setTCUs(updatedTCUs);
      setFilteredTCUs(updatedTCUs);
    }
  }, [qTCUs, isPageLoaded]);

  const handleSearch = (value) => {
    const searchValue = value.toLowerCase();
    const searchTableData = tcus.filter((item) => {
      return Object.keys(item).some((key) => {
        return (
          item[key] && item[key].toString().toLowerCase().includes(searchValue)
        );
      });
    });
    setFilteredTCUs(searchTableData);
  };

  const columns = [
    {
      title: "Index",
      dataIndex: "index",
      key: "index",
      disabledCheckbox: false,
      default: true,
      defaultSortOrder: "descend",
      sorter: (a, b) => a.index - b.index,
    },
    {
      title: "TCU Name",
      dataIndex: "name",
      key: "name",
      disabledCheckbox: false,
      default: false,
    },
    {
      title: "Token",
      dataIndex: "token",
      key: "token",
      disabledCheckbox: false,
      default: false,
    },
    {
      title: "TCU Unit Number",
      dataIndex: "unit_number",
      key: "unit_number",
      disabledCheckbox: false,
      default: false,
    },
    {
      title: "TCU Firmware Version",
      dataIndex: "firmware_version",
      key: "tcu_firmware_version",
      disabledCheckbox: false,
      default: false,
    },
    {
      title: "Application Unit",
      dataIndex: "application_unit_name",
      key: "application_unit_name",
      disabledCheckbox: false,
      default: false,
      render: (text, record) => {
        if (record.product_sn) {
          return (
            <>
              <Icon
                style={{
                  filter: GetFilter(iconColor),
                }}
                hostedImage={Defines.S3_MAP_PIN_UNIT_A}
              />
              {" " + text}
            </>
          );
        } else {
          return (
            <>
              <span style={{ color: "gray" }}>
                <ErrorExIcon /> Link a unit in TCU Settings
              </span>
            </>
          );
        }
      },
    },
    {
      title: "IMEI",
      dataIndex: "imei",
      key: "imei",
      disabledCheckbox: false,
      default: false,
    },
  ];

  if (!isPageLoaded) return <Spinner />;

  return (
    <>
      <Row>
        <Col span={12}>
          <Title level={4}>
            <SummaryWrapper>
              <SummaryTitle>SUMMARY:</SummaryTitle>
              <SummaryBlock>
                <span style={{ textWrap: "nowrap" }}>
                  <SummaryNumber>{numOfActiveTCUs}</SummaryNumber>{" "}
                </span>
                <ConnectedIcon style={{ margin: "0 5px 0 20px" }} />{" "}
                <span style={{ textWrap: "nowrap" }}>Active</span>
              </SummaryBlock>
              <SummaryBlock>
                <span style={{ textWrap: "nowrap" }}>
                  <SummaryNumber>{numOfUnlinkedTCUs}</SummaryNumber>{" "}
                </span>
                <ErrorExIcon style={{ margin: "0 5px 0 20px" }} />
                <span style={{ textWrap: "nowrap" }}>Unlinked</span>
              </SummaryBlock>
            </SummaryWrapper>
          </Title>
        </Col>
        <Col style={{ padding: "24px 0" }} span={6} offset={6}>
          <Input
            prefix={
              <SearchOutlined
                className="site-form-item-icon"
                style={{ color: "lightgray" }}
              />
            }
            placeholder={`Search`}
            onChange={(e) => {
              handleSearch(e.target.value);
            }}
          />
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Table columns={columns} dataSource={filteredTCUs} />
        </Col>
      </Row>
    </>
  );
};
