import SettingsField from "features/widgets/widgetlibrary/SettingsField";
export const TimelineGraphSettings = ({ changeSettings, getField }) => {
  return (
    <div>
      <SettingsField label="Title">
        <input
          value={getField("widgetTitle", "")}
          onChange={(e) => changeSettings("widgetTitle", e.target.value)}
        />
      </SettingsField>
      <SettingsField label="Font-Family">
        <select
          value={getField("widgetFontFamily", "1")}
          onChange={(e) => changeSettings("widgetFontFamily", e.target.value)}
        >
          <option value="1">Default</option>
          <option value="2">Rubik</option>
          <option value="3">PilatWide</option>
        </select>
      </SettingsField>
      <SettingsField label="X-Title">
        <input
          value={getField("widgetXTitle", "")}
          onChange={(e) => changeSettings("widgetXTitle", e.target.value)}
        />
      </SettingsField>
      <SettingsField label="Y-Title">
        <input
          value={getField("widgetYTitle", "")}
          onChange={(e) => changeSettings("widgetYTitle", e.target.value)}
        />
      </SettingsField>
      <SettingsField label="Graph Type">
        <select
          value={getField("widgetGraphType", "total_paired_units_per_month")}
          onChange={(e) => changeSettings("widgetGraphType", e.target.value)}
        >
          <option value="total_paired_units_per_month">
            Total Paired Units per Month
          </option>
          <option value="overall_total_paired_units_per_month">
            Overall Total Paired Units per Month
          </option>
        </select>
      </SettingsField>
      <SettingsField label="Relative Range">
        <select
          value={getField("widgetRelativeTimeRange", "last_year")}
          onChange={(e) =>
            changeSettings("widgetRelativeTimeRange", e.target.value)
          }
        >
          <option value="last_week">Last Week</option>
          <option value="last_month">Last Month</option>
          <option value="last_three_months">Last 3 Months</option>
          <option value="last_six_months">Last 6 Months</option>
          <option value="last_year">Last Year</option>
        </select>
      </SettingsField>
    </div>
  );
};
