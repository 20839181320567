import styled from "styled-components";
import { ThemeProvider } from "styled-components";
import { ConfigProvider } from "antd";
import { DataManager, SessionManager } from "providers";
import { TopNav } from "../TopNav";
import { Sidebar } from "../Sidebar";
import { NotificationContainer } from "features/notifications";
import {
  useCurrentCustomer,
  useCustomerTheme,
} from "features/customers/hooks/customersHooks";
import { CustomerUnverifiedModal } from "features/customers/components";

import { CustomerSubscriptionGuard } from "features/customers/components";

const ViewportWrapper = styled.div`
  width: ${(props) => `calc(100vw - ${props.theme.sidebarWidth})`};
  background-color: #f0f0f0;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  input:focus {
    border-color: ${(props) => props.theme.themeSecondary};
  }
`;

const MainAppFrame = styled.div``;

const AppBody = styled.div`
  display: flex;
  position: fixed;
  top: ${(props) => props.theme.headerHeight};
  height: ${(props) => `calc(100vh - ${props.theme.headerHeight})`};
`;

export const MainLayout = ({ children }) => {
  const currentCustomer = useCurrentCustomer();
  const theme = useCustomerTheme();
  const antDesignTheme = {
    components: {
      token: {
        fontFamilyCode: "Roboto, Arial",
      },
      Button: {
        colorPrimary: theme.themePrimary,
        defaultActiveBg: "none",
        textHoverBg: "none",
        /* here is your component tokens for Ant Design*/
      },
    },
  };

  return (
    <ThemeProvider theme={theme}>
      <ConfigProvider theme={antDesignTheme}>
        <MainAppFrame>
          <SessionManager>
            <DataManager>
              <CustomerSubscriptionGuard>
                {/* @TODO: Handle creation of company down the line */}
                {/* {!currentCustomer && <CreateCompanyModal />} */}
                {currentCustomer && !currentCustomer.is_verified && (
                  <CustomerUnverifiedModal />
                )}
                <TopNav />
                <AppBody>
                  <Sidebar />
                  <ViewportWrapper className="viewport-wrapper">
                    {children}
                  </ViewportWrapper>
                </AppBody>
                <NotificationContainer position="bottom-right" />
              </CustomerSubscriptionGuard>
            </DataManager>
          </SessionManager>
        </MainAppFrame>
      </ConfigProvider>
    </ThemeProvider>
  );
};
