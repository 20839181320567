import { useState } from "react";
import { useDispatch } from "react-redux";
import * as Defines from "utils/defines";
import styled, { useTheme } from "styled-components";
import { withSize } from "react-sizeme";
import { useHistory } from "react-router-dom";
import GoogleMapReact from "google-map-react";
import { debounce } from "lodash";
import { ExpandAltOutlined } from "@ant-design/icons";
import { GetFilter } from "utils/Color";
import { MapStyling } from "styles/GlobalStyles";
import { Icon } from "common";
import { Button } from "antd";
import { Spinner } from "common/Spinner";
import { useDataHistory } from "features/units/hooks";
import { setUnitsViewMode } from "features/grid-layout/redux/layoutSlice";
import { GOOGLE_MAPS_API_KEY } from "config";
import { useCustomerId } from "features/customers/hooks/customersHooks";
import { useGetApplicationUnitsByFilterQuery } from "features/units/redux";
import { useGetProductSettingsForCustomerQuery } from "features/applications/redux";
import { setCurrentProduct as setCurrentSNId } from "features/applications/redux";
import { setActiveMapMarker } from "features/map/redux";
import { useUpdateWidgetInstanceSettingsMutation } from "features/widgets/redux";
import { statusFromTimestamp } from "features/units/hooks";

const MapPin = ({ timestamp, mapPinColorFilter }) => {
  const status = statusFromTimestamp(timestamp);
  const styles = {
    live: { filter: mapPinColorFilter },
    lastDay: {
      filter: `grayscale(20%) brightness(0.85)`,
      opacity: "85%",
    },
    lastWeek: {
      filter: `grayscale(40%) brightness(0.65)`,
      opacity: "65%",
    },
    lastMonth: {
      filter: ` grayscale(60%) brightness(0.50)`,
      opacity: "50%",
    },
    overAMonth: {
      filter: `grayscale(80%) brightness(0.4)`,
      opacity: "40%",
    },
  };

  const style = styles[status] || { filter: mapPinColorFilter };

  return <Icon style={{ ...style }} hostedImage={Defines.S3_MAP_PIN_UNIT_A} />;
};

const MinimapWrapper = styled.div`
  cursor: pointer;
  height: ${({ size }) => (size && size.height ? `${size.height}px` : `100%`)};
  position: relative;
  width: ${({ size }) => (size && size.width ? `${size.width}px` : `100%`)};
`;

const NoPinsMessage = styled.div`
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 5;
`;

const getCenterFromPoints = (points) => {
  const latSum = points.reduce((latSum, point) => point.lat + latSum, 0);
  const lngSum = points.reduce((lngSum, point) => point.lng + lngSum, 0);

  const latCenter = latSum / points.length;
  const lngCenter = lngSum / points.length;

  return {
    lat: isNaN(latCenter) ? 0 : latCenter,
    lng: isNaN(lngCenter) ? 0 : lngCenter,
  };
};

const ReusableMap = ({ points, size, instanceId, instanceSettings }) => {
  const history = useHistory();
  const [zoomLevel, setZoomLevel] = useState(instanceSettings?.zoom || 15);
  const [center, setCenter] = useState(
    instanceSettings?.center ||
      getCenterFromPoints(points) ||
      Defines.DEFAULT_MAP_CENTER
  );

  const theme = useTheme();
  const primaryTheme = theme?.themePrimary;
  const dispatch = useDispatch();
  const setViewMode = (viewMode) => dispatch(setUnitsViewMode(viewMode));
  const setMapPin = (productSNId) => dispatch(setActiveMapMarker(productSNId));
  const unselectCurrentSNId = () => dispatch(setCurrentSNId(null));

  // future color picker feature
  // const [isColorPickerExpanded, setIsColorPickerExpanded] = useState(false);
  // const [liveColorHex, setLiveColorHex] = useState(
  //   instanceSettings?.live || theme.themePrimary
  // );
  // const [dayColorHex, setDayColorHex] = useState(
  //   instanceSettings?.day || theme.themePrimary
  // );
  // const [lastWeekColorHex, setLastWeekColorHex] = useState(
  //   instanceSettings?.week || theme.themePrimary
  // );
  // const [weekColorHex, setWeekColorHex] = useState(
  //   instanceSettings?.week || theme.themePrimary
  // );
  // const [monthColorHex, setMonthColorHex] = useState(
  //   instanceSettings?.month || theme.themePrimary
  // );
  // const [olderColorHex, setOlderColorHex] = useState(
  //   instanceSettings?.older || theme.themePrimary
  // );

  // const LIVE = "live";
  // const DAY = "day";
  // const WEEK = "week";
  // const MONTH = "month";
  // const OLDER = "older";

  const HYBRID = Defines.MAP_TYPE_HYBRID;
  const ROAD_MAP = Defines.MAP_TYPE_ROADMAP;
  const SATELLITE = Defines.MAP_TYPE_SATELLITE;
  const TERRAIN = Defines.MAP_TYPE_TERRAIN;

  const mapTypes = [
    { id: SATELLITE, label: "Satellite", right: 55 },
    { id: ROAD_MAP, label: "Road Map", right: 140 },
    { id: TERRAIN, label: "Terrain", right: 225 },
    { id: HYBRID, label: "Hybrid", right: 310 },
  ];

  const baseButtonStyles = {
    backgroundColor: "#fff",
    border: "1px solid #ddd",
    bottom: 15,
    color: "#1890ff",
    fontSize: "12px",
    height: "30px",
    position: "absolute",
    width: "80px",
    zIndex: 999,
  };

  const MapTypeButtons = () => {
    return mapTypes.map((mapType) => (
      <Button
        key={mapType.id}
        style={{
          ...baseButtonStyles,
          borderColor: mapTypeId === mapType.id && "#1890ff",
          right: mapType.right,
        }}
        onClick={() => handleMapTypeChange(mapType.id)}
      >
        {mapType.label}
      </Button>
    ));
  };

  const handleExpandClick = (e) => {
    history.push("/units");
    setMapPin(null);
    setViewMode("map");
    unselectCurrentSNId();
  };

  const [isInteracting, setIsInteracting] = useState(false);

  const [mapTypeId, setMapTypeId] = useState(
    instanceSettings?.mapTypeId ?? ROAD_MAP
  );

  const [updateWidgetInstanceSettings] =
    useUpdateWidgetInstanceSettingsMutation();

  const handleInteractionStart = () => {
    setIsInteracting(true);
  };

  const handleInteractionEnd = () => {
    setIsInteracting(false);
  };

  const handleMapChange = ({ center, zoom }) => {
    setCenter(center);
    setZoomLevel(zoom);
    updateWidgetInstanceSettings({
      id: instanceId,
      settings: {
        ...instanceSettings,
        center: center,
        zoom: zoom,
      },
    });
  };

  const handleMapTypeChange = (mapTypeId) => {
    setMapTypeId(mapTypeId);
    updateWidgetInstanceSettings({
      id: instanceId,
      settings: { ...instanceSettings, mapTypeId },
    });
  };

  // future color picker feature
  // const handleColorChange = (color) => {
  //   const colorHex = color.toHexString();
  //   updateWidgetInstanceSettings({
  //     id: instanceId,
  //     settings: {
  //       ...instanceSettings,
  //       mapPinColor: colorHex,
  //     },
  //   });
  // };

  // const hexToOpacity = (hex) => {
  //   const decimal = parseInt(hex, 16) / 255;
  //   return decimal.toFixed(2); // Optional: round to 2 decimal places
  // };

  return (
    <div style={{ height: "100%", position: "relative" }}>
      <MapTypeButtons />
      <Button
        style={{
          alignItems: "center",
          bottom: 15,
          display: "flex",
          fontSize: "20px",
          height: "30px",
          justifyContent: "center",
          position: "absolute",
          right: 15,
          width: "30px",
          zIndex: 999,
        }}
        onClick={handleExpandClick}
      >
        <ExpandAltOutlined />
      </Button>
      <MinimapWrapper size={size} className="minimapWrapper">
        {!points.length && (
          <NoPinsMessage>No Recent Location Data</NoPinsMessage>
        )}
        <GoogleMapReact
          bootstrapURLKeys={{ key: GOOGLE_MAPS_API_KEY }}
          center={center}
          draggable={true}
          options={{
            styles: MapStyling.GoogleMapStyle_Big,
            mapTypeId: mapTypeId,
          }}
          onChange={isInteracting ? () => {} : handleMapChange}
          onDragStart={handleInteractionStart}
          onDragEnd={handleInteractionEnd}
          onZoomAnimationStart={handleInteractionStart}
          onZoomAnimationEnd={handleInteractionEnd}
          style={{
            cursor: "pointer",
            background: "clear",
          }}
          yesIWantToUseGoogleMapApiInternals
          zoom={zoomLevel}
        >
          {points
            ?.filter(
              (mapPin) =>
                !isNaN(Number(mapPin?.lat)) && !isNaN(Number(mapPin?.lng))
            )
            .map((mapPin) => {
              return (
                <MapPin
                  key={mapPin?.psnId}
                  lat={mapPin?.lat}
                  lng={mapPin?.lng}
                  timestamp={mapPin?.timestamp_recorded}
                  mapPinColorFilter={GetFilter(primaryTheme)}
                />
              );
            })}
        </GoogleMapReact>
        {/* future color picker feature */}
        {/* <ColorPicker
          format={"hex"}
          defaultValue={liveColorHex}
          value={liveColorHex}
          onChangeComplete={(color) => handleColorChange(color, LIVE)}
          style={{ margin: "5px", position: "absolute" }}
          showText={() => <span>Live</span>}
        />
        <Button
          type="text"
          icon={
            isColorPickerExpanded ? (
              <UpOutlined style={{ marginTop: "3px" }} />
            ) : (
              <DownOutlined style={{ marginTop: "3px" }} />
            )
          }
          onClick={() => setIsColorPickerExpanded(!isColorPickerExpanded)}
          style={{
            backgroundColor: "#fff",
            border: "1px solid #ddd",
            borderRadius: "6px",
            left: "80px",
            padding: "4px 8px",
            position: "absolute",
            top: "5px",
          }}
        /> */}
        {/* {isColorPickerExpanded && (
          <>
            <ColorPicker
              format={"hex"}
              style={{ margin: "5px", position: "absolute", top: "40px" }}
              showText={() => <span>Last Day</span>}
              onChangeComplete={(color) => handleColorChange(color, DAY)}
              defaultValue={dayColorHex}
              value={dayColorHex}
            />
            <ColorPicker
              format={"hex"}
              style={{ margin: "5px", position: "absolute", top: "80px" }}
              showText={() => <span>Last Week</span>}
              onChangeComplete={(color) => handleColorChange(color, WEEK)}
              defaultValue={weekColorHex}
              value={weekColorHex}
            />
            <ColorPicker
              format={"hex"}
              style={{ margin: "5px", position: "absolute", top: "120px" }}
              showText={() => <span>Last Month</span>}
              onChangeComplete={(color) => handleColorChange(color, MONTH)}
              defaultValue={monthColorHex}
              value={monthColorHex}
            />
            <ColorPicker
              format={"hex"}
              style={{ margin: "5px", position: "absolute", top: "160px" }}
              showText={() => <span>Over a Month</span>}
              onChangeComplete={(color) => handleColorChange(color, OLDER)}
              defaultValue={olderColorHex}
              value={olderColorHex}
            />
          </>
        )} */}
      </MinimapWrapper>
    </div>
  );
};

const DashboardMapWidget = ({ size, instance }) => {
  const customerId = useCustomerId();
  const dataHistory = useDataHistory();
  const instanceId = instance?.id;
  const instanceSettings = instance?.settings
    ? JSON.parse(instance.settings)
    : {};

  const { data: allProductSettings } =
    useGetProductSettingsForCustomerQuery(customerId);

  const { data: productSNs, isLoading: productSNsIsLoading } =
    useGetApplicationUnitsByFilterQuery({
      customer_id: customerId,
    });

  if (productSNsIsLoading) return <Spinner />;
  if (!productSNs?.length) return <div>No Product SNs</div>;
  const locations = [];

  for (let productSN of productSNs) {
    const dataHistoryForPSN = dataHistory[productSN.id];
    const { lat_signal_id, lng_signal_id } =
      allProductSettings.find(
        (productSettingObj) =>
          productSettingObj.product_id === productSN.product_id
      ) || {};

    // GPS Lat
    const unitLatData = dataHistoryForPSN
      ? dataHistoryForPSN[lat_signal_id]
      : null;

    const unitLatVal = unitLatData
      ? unitLatData[unitLatData.length - 1].data_value
      : null;

    // GPS Lng
    const unitLngData = dataHistoryForPSN
      ? dataHistoryForPSN[lng_signal_id]
      : null;
    const unitLngVal = unitLngData
      ? unitLngData[unitLngData.length - 1].data_value
      : null;

    const unitLatTimestamp =
      unitLatData && unitLatData[unitLatData?.length - 1]?.timestamp_recorded;

    if (unitLatVal && unitLngVal)
      locations.push({
        lat: unitLatVal,
        lng: unitLngVal,
        psnId: productSN.id,
        timestamp_recorded: unitLatTimestamp,
      });
  }

  return (
    <ReusableMap
      instanceId={instanceId}
      instanceSettings={instanceSettings}
      points={locations}
      size={size}
    />
  );
};

export const DashboardMap = withSize()(DashboardMapWidget);
